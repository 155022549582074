.member-container {
    .member-content {
        background-color: #F5F8FC;
        border-radius: 5px;

        .member-sub-content {
            .content-title {
                font: normal normal bold 24px/32px Roboto;
                letter-spacing: 0px;
                color: #2580EC;
            }

            .box-content {
                // margin-bottom: 15px;
                // margin: 15px !important;

                .box {
                    // width: 129px;
                    height: 108px;
                    padding: 10px;
                    margin: 15px;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border: 0.5px solid #417EE4;
                    border-radius: 10px;
                    cursor: pointer;
                    
                    .content-sub-title {
                        font-size: normal normal medium 14px/19px Roboto;
                        letter-spacing: 0px;
                        color: #000000;
                        display: flex;
                        text-align: center;
                        margin-bottom: 10px;
                    }
                }
            }
        }
        .box-content {
            .box:hover {
                -webkit-box-shadow: 0px 0px 10px 2px rgba(65, 126, 228, 0.76);
                -moz-box-shadow: 0px 0px 10px 2px rgba(65, 126, 228, 0.76);
                box-shadow: 0px 0px 10px 2px rgba(65, 126, 228, 0.76);
                border: none;
            }
        }
    }
}

@media screen and (max-width:767px) { 
.member-container {
    width: 100%;
}
.box {
    width: 100% !important; 
    margin: 0 !important;
}
.member-sub-content {
    max-width: 100% !important;
}
.content-title {
    margin: 0 !important;
    font-size: 15px !important;
}
.content-sub-title {
    font-size: 10px;
    height: 70px;
    padding-bottom: 5px;
    // padding: 5px;
}
.member-sub-content .box{
    height: 90px !important;
    margin: 10px !important;
    padding: 0 !important;
}
.member-sub-content img{
    height: 15px !important;
    width: 15px;
}
}

@media screen and (min-width:767px) and (max-width:968px) { 
    .member-sub-content .box{
      height: 150px !important;
    }    
}