.view-college-container {
    padding: 20px;
    position: absolute;
    transition: 0.5s;
    top: 4rem;
    overflow: auto;

    p {
        margin: 0;
        padding: 0;
    }

    .label-input {
        position: relative;
        // width: 100%;
        min-width: 25rem;
        font-family: 'Roboto';
    }

    .search-btn {
        box-shadow: 0px 3px 6px #00000029;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        color: #fff;
        display: flex;
        align-items: center;
        padding: 5px 20px;
        margin-left: 5px;
        outline: none;
        font-size: 16px;
        font-weight: 500;
        gap: 10%;
        background: #5284EE 0% 0% no-repeat padding-box;
        border: none;
        border-radius: 22px;
    }

    .search-input {
        padding: 10px 25px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        border-radius: 22px;
        // border: 1px solid #707070;
        font-size: 16px;
    }

    .view-text {
        font-size: 24px;
        font-weight: 700;
    }

    .main-container {
        // padding: 2% 0;
        // margin: 2% 4% 0 0;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        // border: 1px solid #707070;
        border-radius: 20px;
        opacity: 1;
        overflow: auto;

        .delete-all-btn {
            background: #5284EE 0% 0% no-repeat padding-box;
            border-radius: 5px;
            color: white;
            border: none;
            padding: 10px 20px;
        }
    }

    .custom-header {
        background-color: #4CAF50;
        /* Green */
        color: white;
    }

    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        .label-input {
            min-width: 15rem;
        }
    }

    .noDataFoundSection {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        .noDataFound {
            text-align: left;
            font: normal normal bold 24px/32px Roboto;
            letter-spacing: 0px;
            color: #000000;
        }
    }

}

.actionBtn {
    background: unset;
    border: none;
}

#dropdown-basic {
    background: none;
    width: 27px;
    height: 27px;
    border: none;
    border-radius: 50%;

}

.actionBtnIcon {
    position: absolute;
    left: 39%;
    top: 0;
}

.custom-input {
    height: 45px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 2px #00000029;
    border-radius: 5px;
    width: 100%;
    margin-bottom: 1rem;
    border: none;
}

.down-arrow {
    position: absolute;
    right: 25px;
    top: 10px;
}
.TableRow{
    height: 65px !important;
}