.grievance-container {
    background-color: #F7F9FA;
    min-height: calc(100% - 4rem);
    font-family: 'Roboto';

    .backBtn {
        display: flex;
        align-items: center;
    }

    .backText {
        font: normal normal medium 16px/21px Roboto;
        letter-spacing: 0px;
        color: #000000;
        margin-left: 10px;
        cursor: pointer;
        margin-left: 12px;
    }

    .grievance-card {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 5px;

        .filter-section {
            column-gap: 30px;
            row-gap: 10px;

            .all-complaints {
                font: normal normal bold 24px/32px Roboto;
                letter-spacing: 0px;
                color: #000000
            }

            .search-filter {
                .custom-search-input {
                    // max-width: 426px;
                    height: 40px;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border: 1px solid #707070;
                    width: 100%;
                    outline: unset;
                }

                .search-btn {
                    height: 40px;
                    background: #32496B 0% 0% no-repeat padding-box;
                    // box-shadow: 0px 3px 6px #00000029;
                    border: 1px solid #32496B;
                    font: normal normal medium 16px/21px Roboto;
                    letter-spacing: 0.51px;
                    color: #FFFFFF;
                }
            }

            .custom-select-input {
                // max-width: 164px;
                height: 40px;
                width: 100%;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #707070;
                outline: none;
                font: normal normal normal 16px/21px Roboto;
                letter-spacing: 0px;
                color: #000000;
            }
        }

        .table-row {
            background: #EAF2FC 0% 0% no-repeat padding-box;

            .head-title {
                font: normal normal normal 16px/21px Roboto;
                letter-spacing: 0px;
                color: #000000;
                font-weight: 500;
            }
        }

        .table-data {
            font: normal normal normal 16px/21px Roboto;
            letter-spacing: 0px;
            color: #000000;
            border-bottom: unset;
        }
    }
}