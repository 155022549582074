#sideNavContainer {
    width: 19%;
    p {
        margin: 0 !important;
        padding: 0 !important;
    }

    .sidebar-option {
        cursor: pointer;
        font-family: 'Roboto';
        line-height: 26px;
    }

    transition: ease 0.5s;
    position: fixed;
    height: 100vh;
    background-color: #fff;
    // width: 20%;
    z-index: 112;
    border: 0.5px solid #2480EB;
    opacity: 1;
    top: 64px;
    border-top: none;

    .sideNavBtnCard {
        margin-top: 1.25rem;

        .title {
            opacity: 1;
            color: #212c48;
            font-family: 'Roboto';
            font-size: 20px;
            line-height: 26px;
            font-weight: 700;
        }
    }

    .sideNav-scroll {
        margin-top: 1rem;
        height: calc(100vh - 3rem);
        overflow: auto;
    }

    @media only screen and (max-width: 992px) {
        .sideNavBtnCard {
            padding: 0 !important;
        }

        // ul {
        //     padding-left: 7% !important;
        // }

        .parentNavItems {
            padding: 5px !important;
        }

        .childNavItems {
            padding: 8px 20px !important;
            margin-left: 0.75rem;
        }
    }

    .parentNavItems {
        color: #000;
        text-decoration: none;
        display: block;
        padding: 15px;
        display: flex;
        gap: 15px;
        align-items: center;
        font-size: 20px;
        font-weight: 400;
        padding-left: 2.2rem;
        position: relative;
    }

    .active {
        // background: #7d9ff5 0% 0% no-repeat padding-box;
        // font: 700 20px/26px 'Roboto';
        // border-bottom: 3px solid #70707080;
        // letter-spacing: 0px;
        // color: #fff !important;
        // opacity: 1;
        // cursor: pointer;
        // position: relative;
        // // border-radius: 0 0 0 29px;
        // font-weight: 500 !important;

        height: 50px;
    background: #D5587F 0% 0% no-repeat padding-box;
    font: 700 20px/26px Roboto;
    border-bottom: 3px solid #F9B55C;
    letter-spacing: 0px;
    color: #fff;
    opacity: 1;
    // padding: 0 20px;
    cursor: pointer;
    position: relative;
    border-radius: 0 0 0 29px;
    }

    .sideNavClosed {
        .parentNavItems{
            justify-content: center;
            padding-left: 1rem;
        }
    }

    .imgActive{
        filter: invert(1);
    }

    .imgRotate{
        transform: rotate(180deg);
    }

    ul {
        list-style-type: none;
        padding-left: 0rem;
    }

    .childNavItems{
        color: #000;
        text-decoration: none;
        display: block;
        padding: 15px;
        display: flex;
        gap: 1rem;
        align-items: center;
        font-size: 16px;
        font-weight: 400;
        padding-left: 2.2rem;
        margin-left: 1.25rem;
        cursor: pointer;
    }

    .childNavItems:hover{
        background-color: #c4dcf8;
        border-radius: 0 0 0 29px;
    }

    .child-active {
        background: #212C48 0% 0% no-repeat padding-box;
        font: 700 16px/22px 'Roboto';
        border-bottom: 3px solid #70707080;
        letter-spacing: 0px;
        color: #fff !important;
        opacity: 1;
        cursor: pointer;
        position: relative;
        border-radius: 0 0 0 29px;
        font-weight: 500 !important;
    }

      /* width */
      ::-webkit-scrollbar {
        width: 4px;
      }
      
      /* Track */
      ::-webkit-scrollbar-track {
        box-shadow: inset 0 0 5px grey;
        border-radius: 10px;
      }

      ::-webkit-scrollbar-track-piece {
        background: #ffffff;
      }
      ::-webkit-scrollbar-thumb {
        background-color: #0F1933;
        border-radius: 10px;
      }

      .closeIcon {
        width: 24px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0 3px 3px #01034c88;
        border-radius: 3px;
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
      }
}


// .sideNavOpenWidth{
//     width: 15%;
// }

.sideNavCloseWidth {
    width: 0px !important;
}

// @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
//     #sideNavContainer{
//         .title{
//             display: none;
//         }
//     }

//     .sideNavOpenWidth{
//         width: 0px;
//         display: none;
//     }
    
//     .sideNavCloseWidth {
//         width: 25%;
//     }

//     .arrow-icon{
//         display: none;
//     }
// }
// @media only screen and (max-width: 629px) {
//     #sideNavContainer{
//      top: 122px;
//     }
//  }

 @media screen and (max-width:767px) {
    #sideNavContainer{
        width: 100%;
    }
    .sideNavOpenWidth{
        width: 0px;
    }
    .sideNavCloseWidth {
        width: 100%;
    }
 }
