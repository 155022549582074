#marks-input-screen{
    position: absolute;
    transition: 0.5s;
    top: 4rem;
    overflow: auto;
    font-family: 'Roboto';

    p{
        margin: 0;
        padding: 0;
    }

    .back-btn-card{
        display: inline-flex;
        align-items: center;
        gap: 1rem;
        cursor: pointer;
        font-size: 20px;
        font-weight: 500;
        margin-left: 1rem;
    }

    .title-text {
        font-size: 24px;
        font-weight: 700;
        padding: 0 2% 2% 1%
    }

    .blue-card{
        background: #EAF2FC 0% 0% no-repeat padding-box;
        border-radius: 10px;
        font-size: 20px;
        font-weight: 500;
        padding: 1rem 2rem;
    }

    .main-container{
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 20px;
        overflow: auto;
        padding: 1rem;
        margin: 0 1rem;
    }

    .remarks-card {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 1rem 0;
    }

    .check-box-card{
        display: flex;
        align-items: center;
        width: 100%;
        gap: 5%;

        p{
            font-size: 20px;
            font-weight: 500;
        }
    }

    .label-pass {
        font-size: 16px;
        margin-left: 1rem;
        color: #3EA33E;
    }

    .label-fail {
        font-size: 16px;
        margin-left: 1rem;
        color: #D82424;
    }

    .table-container {
        .custom-table{
            width: 100%;

            thead{
                background: #76A5DE;
                height: 3.5rem;
                text-align: center;
                font-size: 18px;
            }

            tfoot, tbody{
                text-align: center;
                font-size: 20px;

                td{
                    height: 4rem;
                }
            }
        }
    }

    .marks-input{
        width: 4.5rem;
        padding: 0.3rem;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #707070;
        border-radius: 5px;
        margin-right: 0.5rem;
        color: #707070;
        font-size: 18px;
    }

    .user-image-card {
        display: flex;
        justify-content: center;
        margin-top: 5%;
    }

    .user-image{
        border-radius: 50%;
        background-color: aliceblue;
        width: 13rem;
        height: 13rem;
    }

    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        .main-container{
            min-width: 70rem;
        }
    }
}