.calendar-container {
    background-color: #FFFFFF;
    min-height: calc(100% - 4rem);
    font-family: 'Roboto';

    .backBtn {
        display: flex;
        align-items: center;
    }

    .backText {
        font: normal normal medium 16px/21px Roboto;
        letter-spacing: 0px;
        color: #000000;
        margin-left: 10px;
        cursor: pointer;
        margin-left: 12px;
    }

    .calendar-card {
        .add-event-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 124px;
            height: 42px;
            background: #32496B 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            font: normal normal medium 16px/21px Roboto;
            letter-spacing: 0px;
            color: #FFFFFF;
        }

        .calendar-select-input {
            max-width: 137px;
            height: 30px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 0.5px solid #70707071;
            width: 100%;
            outline: unset;
        }
    }
}