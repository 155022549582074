// request-report

#request-report {
  .backText {
    font-size: 18px;
    font-weight: bold;
    padding: 2px;
    cursor: pointer;
  }

  .btn-icon {
    height: 30px;
    width: 16px;
    cursor: pointer;
  }

  .back-btn {
    padding: 25px;
    margin-top: 55px;
  }

  .main-container {
    padding: 1rem;
    overflow: auto;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 2px #00000029;
    border: 0.5px solid #7070704d;
    border-radius: 5px;
  }
}

// audit-report

#audit-report {
  .select-option {
    padding: 6px;
    width: 280px;
    margin: 27px;
  }

  .btn-group {
    display: flex;
    justify-content: center;
    gap: 26px;
    margin-top: 29px;
  }

  .report-btn {
    width: 149px;
    border: none;
    background: var(--unnamed-color-5385ef) 0% 0% no-repeat padding-box;
    background: #5385ef 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    color: #ffff;
    padding: 5px;
  }

  .clear-btn {
    width: 149px;
    border: 1px solid #5385ef;
    border-radius: 5px;
    color: #5385ef;
    padding: 5px;
    background-color: #FFF;
  }

  .radio-label {
    position: absolute;
    margin-top: 0px;
    font-weight: bold;
  }
}

// specific-report

#specific-report {
  .radio-label {
    position: absolute;
    margin-top: 0px;
    font-weight: bold;
  }

  .radio-box {
    gap: 20px;
  }

  .btn-group {
    display: flex;
    justify-content: center;
    gap: 26px;
    margin-top: 29px;
  }

  .report-btn {
    width: 149px;
    border: none;
    background: var(--unnamed-color-5385ef) 0% 0% no-repeat padding-box;
    background: #5385ef 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    color: #ffff;
    padding: 5px;
  }

  .clear-btn {
    width: 149px;
    border: 1px solid #5385ef;
    border-radius: 5px;
    color: #5385ef;
    padding: 5px;
    background-color: #FFF;
  }

  .report-tabs {
    background-color: #e7eefe;
    gap: 50px;
    width: 50%;
    margin-top: 16px;
    margin-bottom: 24px;

    h3 {
      font-size: 16px;
      margin-left: 26px;
      margin-top: 8px;
      padding: 3px;
    }
  }

  .report-tabs h3 {
    cursor: pointer;
    padding: 10px 20px;
    margin: 0;
    border-bottom: 2px solid transparent;
    transition: border-bottom 0.3s;
  }

  .report-tabs h3.active {
    border-bottom: 2px solid #007bff;
    border-radius: 4px;
    color: #007bff;
  }
    .form-select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  }
}

#export-document {
  .card-row {
    justify-content: center;
    display: flex;
    gap: 131px;
    margin-top: 73px;
  }

  .card-wrapper {
    position: relative;
    height: 250px;
    width: 300px;
    border-radius: 20px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;

    &:hover {


      .card-bottom {
        justify-content: center;
        transform: translate(0%, -50%);
        transition: all 0.8s ease;
        background: transparent linear-gradient(121deg, var(--unnamed-color-d5587f) 0%, #6c63ff 100%) 0% 0% no-repeat padding-box;
        background: transparent linear-gradient(121deg, #d5587f 0%, #6c63ff 100%) 0% 0% no-repeat padding-box;
        box-shadow: inset 0px 3px 6px #f5f6feb5, 0px 3px 6px #00000029;
      }
    }

    box-shadow: 10px 10px 53px 0px rgba(0, 0, 0, 0.49);
  }

  .card-top {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: #e8f0fa 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #f5f6feb5, 0px 3px 6px #00000029;
    border: 0.5px solid #e8f0fa;
    border-radius: 10px;

    h2 {
      text-align: center;
      margin-top: 99px;
    }
  }

  .card-top1 {
    width: 92px;
    height: 92px;
    background-color: red;
    margin-top: -37px;
    position: absolute;
    z-index: 99;
    margin-left: -40px;
    background: transparent linear-gradient(121deg, var(--unnamed-color-d5587f) 0%, #6C63FF 100%) 0% 0% no-repeat padding-box;
    background: transparent linear-gradient(121deg, #D5587F 0%, #6C63FF 100%) 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #F5F6FEB5, 0px 3px 6px #00000029;
    border-radius: 15px;
    opacity: 1;

  }

  .card-bottom {
    width: 100%;
    position: absolute;
    z-index: 20;
    display: nonee;
    top: 50%;
    background-color: rgba(110, 122, 92, 0);
    padding: 100px 20px;
    color: #ffffff;
    transform: translate(100%, -50%);
  }

  .button {
    position: relative;
    display: flex;
    outline: none;
    cursor: pointer;
    margin-top: 25px;
    border: none;
    border-radius: 3px;
    color: #ffffff;
    padding: 5px 20px;
    justify-content: center;
    opacity: 0.7;
    background: transparent linear-gradient(102deg, #FFFFFF66 0%, #FFFFFF24 100%) 0% 0% no-repeat padding-box;
    box-shadow: 15px 15px 50px #0000000D;
    border: 1px solid #FFFFFF40;
    border-radius: 5px;
    opacity: 1;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
  }

  .card-icon {
    width: 50px;
    height: 50px;
  }

  .card-section {
    display: flex;
    justify-content: center;
    margin-top: 21px;
  }
}

.down-arrow {
  position: absolute;
  top: 6px;
  right:25px;
}

.admin-down-arrow {
  position: absolute;
  top: 7px;
  right: 25px;
}

.select-down-arrow {
  position: absolute;
  top: 30px;
  right: 25px;
}

.form-select {
  height: 39px;
}

#reports {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: none;
  background-color: white;
  /* Optional: Set background color */
  padding-right: 10px;
  /* Adjust padding as needed */
  border: 1px solid #ccc;
  /* Optional: Set border style */
}

.hide-dropdown-arrow .MuiSelect-icon {
  display: none;
}

.custom-input {
  height: 39px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 2px #00000029;
  border: 0.5px solid #7070704D;
  border-radius: 5px;
}