.student-csv-container {

    background-color: #F7F9FA;
    min-height: calc(100% - 4rem);
    font-family: 'Roboto';
    padding: 5rem;

    .backText {
        font: normal normal medium 16px/21px Roboto;
        letter-spacing: 0px;
        color: #000000;
        cursor: pointer;
        margin-left: 10px;
    }

    .student-create-csv-card {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        // border: 0.5px solid #707070;
        border-radius: 10px;
        padding: 8px 35px;
        margin-top: 30px;


        .timeline {
            .timeList {
                .download-the-csv-temp {
                    margin: 0 0 15px 7%;
                    background: #CBD6F1;
                    color: black;
                    padding: 2em;
                    font-size: 1.2em;
                    font-weight: 300;
                    line-height: 1.4;
                    position: relative;
                    border-radius: 5px;
                    // margin-left: 3%;

                    .download-the-csv-title {
                        font: normal normal normal 12px/16px Roboto;
                        letter-spacing: 0.24px;
                        color: #000000;
                        font-weight: 500;
                    }

                    .download-csv {
                        .custom-input {
                            height: 45px;
                            background: #FFFFFF 0% 0% no-repeat padding-box;
                            border: 1px solid #707070;
                            border-radius: 5px;
                            width: 212px;
                            margin-bottom: 0.2rem;
                            outline: none;
                            font-size: 12px;
                            color: #707070;
                            display: flex;
                            align-items: center;
                        }
                    }

                    .download-the-csv-note {
                        font: normal normal normal 12px/16px Roboto;
                        letter-spacing: 0.24px;
                    }

                    .download-the-csv-title-second {
                        font: normal normal normal 18px/24px Roboto;
                        letter-spacing: 0px;
                        color: #000000;
                    }

                    .download-the-csv-sub-title {
                        font: normal normal normal 15px/20px Roboto;
                        letter-spacing: 0px;
                        color: #000000;
                        font-weight: 500;
                    }

                    .input-label {
                        font: normal normal normal 14px/19px Roboto;
                        letter-spacing: 0px;
                        color: #000000;
                    }

                    .custom-select-input {
                        height: 45px;
                        background: #FFFFFF 0% 0% no-repeat padding-box;
                        box-shadow: 0px 3px 6px #00000029;
                        border-radius: 5px;
                        width: 100%;
                        margin-bottom: 0.2rem;
                        outline: none;
                        border: none;
                    }

                    select {
                        -webkit-appearance: none;
                        /* remove default arrow in Safari */
                        -moz-appearance: none;
                        /* remove default arrow in Firefox */
                        appearance: none;
                        /* remove default arrow in other browsers */
                        padding: 0 15px;
                        font: normal normal normal 12px/16px Roboto;
                        letter-spacing: 0px;
                        color: #000000;
                    }

                    ::-webkit-input-placeholder {
                        font: normal normal normal 12px/16px Roboto;
                        letter-spacing: 0px;
                        color: #000000;
                    }

                    .down-arrow {
                        position: absolute;
                        top: 10px;
                        right: 15px;
                    }

                    .preview-btn {
                        // width: 69px;
                        height: 34px;
                        background: #2580EC 0% 0% no-repeat padding-box;
                        border-radius: 4px;
                        font-size: normal normal medium 16px/21px Roboto;
                        letter-spacing: 0px;
                        color: #FFFFFF;
                        border: none;
                    }

                    .add-csv-file {
                        text-align: left;
                        font: normal normal normal 14px/19px Roboto;
                        letter-spacing: 0px;
                        color: #000000;
                    }

                    .input-file {
                        font: normal normal normal 12px/16px Roboto;
                        letter-spacing: 0px;
                        color: #000000;
                    }

                    ::-webkit-file-upload-button {
                        width: 99px;
                        height: 28px;
                        background-color: #FFFFFF;
                        box-shadow: 0px 3px 6px #00000029;
                        border: 0.5px solid #707070;
                        border-radius: 4px;
                        margin: 0 10px 0 0;
                    }

                    .table-data-row {

                        th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignCenter.MuiTableCell-sizeMedium.css-1ndpvdd-MuiTableCell-root,
                        td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignCenter.MuiTableCell-sizeMedium.css-1yhpg23-MuiTableCell-root {
                            border: 1px solid;
                        }
                    }

                    .download-csv {
                        position: relative;
                    }

                    .download-icon {
                        position: absolute;
                        top: 7px;
                        left: 187px;
                    }

                    ::-webkit-input-placeholder {
                        font: normal normal medium 12px/16px Roboto;
                        letter-spacing: 0.24px;
                        color: #707070;
                    }
                }
            }
        }

    }
}

.download-the-csv-temp:after {
    right: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-right-color: #CBD6F1;
    border-width: 10px;
    top: 10px;
}

.icon-phone:before {
    content: "0";
}

.icon-screen:before {
    content: "02";
}


.icon-earth:before {
    content: "03";
}

.timeline {
    margin: 30px 0 0 0;
    padding: 0;
    list-style: none;
    position: relative;
}

/* The line */
.timeline:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: 0px;
    border-left: dotted;
    margin-left: 5px;
}

.timeline {
    .timeList {
        position: relative;
    }
}

/* The date/time */
.timeline {
    .timeList {
        .time {
            display: block;
            width: 5%;
            padding-right: 100px;
            position: absolute;
        }
    }
}

.timeline {
    .timeList {
        .icon {
            width: 40px;
            height: 40px;
            font-family: 'ecoico';
            // speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            font-size: 1.4em;
            line-height: 40px;
            -webkit-font-smoothing: antialiased;
            position: absolute;
            color: #000;
            background: #fff;
            border-radius: 50%;
            box-shadow: 0 0 0 8px #2580EC;
            text-align: center;
            left: 1%;
            top: 0;
            margin: 0 0 0 -25px;
        }
    }
}

@media screen and (max-width:767px) {
    .student-csv-container {
        padding: 0;
        margin-top: 150px;
        margin-bottom: 100px;
    }

    .input-file {
        max-width: 100%;
    }

    .download-the-csv-temp {
        display: none;
    }

    .download-the-csv-title {
        display: none;
    }

    .download-the-csv-title {
        font-size: 1px !important;
    }
}