#reports_container{
    position: absolute;
    background-color: #F7F9FA;
    min-height: calc(100% - 4rem);
    font-family: 'Roboto';
    transition: 0.5s;
    top: 4rem;
    padding: 1rem;

    .main-container{
        background-color: #fff;
        border: 1px solid #707070;
        border-radius: 5px;
        padding: 1rem;
        overflow: auto;
    }
}