.college-container {
    position: absolute;
    background-color: #F7F9FA;
    min-height: calc(100% - 4rem);
    font-family: 'Roboto';
    position: absolute;
    transition: 0.5s;
    top: 4rem;

    p {
        margin: 0;
        padding: 0;
    }

    .college-form-card {
        padding: 1%;
        margin: 0.5% 1.5% 1.5% 1.5%;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 0.5px solid #7070704D;
        border-radius: 10px;
        opacity: 1;
    }

    .college-form-title {
        font-size: 24px;
        font-weight: 700;
        padding: 0 2% 2% 1%
    }

    .input-label {
        color: #707070;
        font-size: 16px;
        margin-bottom: 0.5rem;
    }

    .custom-input {
        height: 45px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 2px #00000029;
        border-radius: 5px;
        width: 100%;
        margin-bottom: 1rem;
        border: none;
    }

    .form-submit-card {
        //padding: 2%;
        margin: 1.5% 2%;
        display: flex;
        gap: 1%;

        .save-btn {
            background-color: #5284EE;
            letter-spacing: 0px;
            color: #FFFFFF;
            font: normal normal normal 16px/21px Roboto;
            font-weight: 500;
            border: 2px solid #5284EE;
            border-radius: 5px;
            padding: 10px 30px;
        }

        .cancel-btn {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 2px solid #5284EE;
            border-radius: 5px;
            color: #5284EE;
            padding: 10px 30px;
        }
    }

    .MuiInputBase-formControl {
        height: 44px;
        background: #F2F7FD 0% 0% no-repeat padding-box;
    }

    .Mui-error .MuiOutlinedInput-notchedOutline {
        border-color: grey !important;
    }

    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        .input-label {
            margin-top: 0.5rem;
        }
    }

    .selectCourse {
        color: blue;
        cursor: pointer;
        background: none;
        border: none;
        outline: none;
    }

    .locality {
        color: #2580EC;
    }

    .custom-chip {
        display: flex;
        justify-content: space-between;
        min-width: 147px;
        height: 34px;
        background: #E7EEFE 0% 0% no-repeat padding-box;
        border-radius: 5px;
        font: normal normal normal 16px/21px Roboto;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
    }

}

.search-field {
    .custom-select-input {
        height: 45px !important;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 5px;
        width: 100%;
        margin-bottom: 0.2rem;
        border: none;
        text-align: left;
        font: normal normal normal 16px/21px Roboto;
        font-weight: 500;
        letter-spacing: 0px;
        color: #647599;
        opacity: 0.46;
    }

    .search-btn {
        height: 94%;
        position: absolute;
        right: 0;
        background: #2580EC 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #FFFFFF;
        border-radius: 5px;
        padding: 0 13px;
        font: normal normal normal 16px/21px Roboto;
        font-weight: 500;
        letter-spacing: 0.51px;
        color: #FFFFFF;

        img {
            margin-right: 10px;
        }
    }

    .apply-btn {
        right: 0;
        background: #2580EC 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 1px solid #FFFFFF;
        border-radius: 5px;
        padding: 10px 15px;
        color: white;
    }

}

.courseTable {
    box-shadow: 0px 3px 6px #00000029;
    height: 350px;
    overflow-y: scroll;

    thead {
        background-color: #E7EEFE;
        color: black;
        font: normal normal medium 15px/20px Roboto;
    }

    td {
        padding: 10px;
    }

}

.modal-header {
    display: flex;
    justify-content: space-between;
}

.courseTable {
    table>thead {
        position: sticky;
        z-index: 100;
        top: 0;
    }

    table>thead>tr>th {
        height: 50px !important;
        padding: 0 33px !important;
        color: white;
        background: #E7EEFE 0% 0% no-repeat padding-box;
        font-size: 15px;
        font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    }

    table>tbody>tr>td>div {
        padding: 10px 0px 10px 28px !important;

        label {
            font: normal normal normal 15px/20px Roboto;
            letter-spacing: 0px;
            color: #101A33;
            opacity: 0.6;
        }
    }
}

input[type=checkbox] {
    // position: relative;
    cursor: pointer;
    margin: 0 21px 0 0;
}

// input[type=checkbox]:before {
//     content: "";
//     display: block;
//     position: absolute;
//     width: 16px;
//     height: 16px;
//     top: 0;
//     left: 0;
//     background-color: #e9e9e9;
// }

// input[type=checkbox]:checked:before {
//     content: "";
//     display: block;
//     position: absolute;
//     width: 16px;
//     height: 16px;
//     top: 0;
//     left: 0;
//     background-color: #5284EE;
// }

input[type=checkbox]:checked:after {
    content: "";
    display: block;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 2px;
    left: 2px;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-thumb {
    background: linear-gradient(13deg, #7B7B7B 14%, #7B7B7B 64%);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
    background: linear-gradient(13deg, #7B7B7B 14%, #7B7B7B 64%);
}

::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: inset 7px 10px 12px #ffffff;
}

.gap-11-px {
    gap: 20px
}