.forgot-backg{
    background: url('../../../Assets/login/login-backg.svg');
    background-size: cover;
    margin: 0 !important;
    background-repeat:no-repeat;
  }
  .loginFormContent1 {
    padding: 20px;
  }
  .loginFormContent1 h5 {
    font: normal normal bold 28px/37px Roboto;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;
    padding: 20px;
  }
  .loginFormContent1 p {
    font: normal normal 18px/24px Roboto;
    color: #FFFFFF;
    text-align: center;
    opacity: 1;
    letter-spacing: 0px;
    font-weight: 340;
    padding-left: 130px;
    padding-right: 130px;
  }
  .new-passwords {
    height: 100vh;
  }
  .new-passwords h1 {
    font: normal normal bold 24px/32px Roboto;
    letter-spacing: 0px;
    color: #000352;
    opacity: 1;
    text-align: center;
  }
  .otp-field label{
  text-align: left;
  font: normal normal normal 16px/21px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  }
  .otp-username{
    background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #b616160d;
  border: 0.5px solid #00000040;
  border-radius: 5px;
  opacity: 1;
  backdrop-filter: blur(15px);
  }
  .otp-username::placeholder {
    font: normal normal normal 16px/21px Roboto;
    letter-spacing: 0px;
    color: #000000;
    text-transform: capitalize;
    opacity: 0.32;
  }
  .btn-customized {
    width: 100%;
    border-radius: 5px;
    opacity: 1;
    margin: 0;
    padding: 0;
  }
  .new-passwords p{
    text-align: center;
  font: 12px/16px Roboto;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 500px;
  }
  .form-group i {
    float: right;
    padding: 8px 0px;
    margin-right: 20px;
    top: 4%;
    right: 0%;
    cursor: pointer;
    position: absolute;
  }
  .login-labels{
    font: normal normal normal 20px/26px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  .usernam-password input{
    padding: 10px !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 0.5px solid #72727280;
    border-radius: 5px;
    opacity: 1;
  }
  .new-pass-placeholder::placeholder {
    font: normal normal normal 16px/21px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.32;
  }
  .login-ryt img   {
    width: 200px;
    height: 166px;
  }
  .new-passwords h4{
    font: normal normal bold 28px/37px Segoe UI;
  letter-spacing: 0px;
  color: #000352;
  opacity: 1;
  }
  .forgot-password {
    font: normal normal bold 16px/21px Roboto;
  letter-spacing: 0px;
  color: #582ACD;
  opacity: 1;
  float: right;
  text-align: right;
  cursor: pointer;
  }
  .form-check-input{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 5px;
    opacity: 1;
    cursor: pointer;
  }
  .form-check-label {
    padding-top: 5px;
    font: normal 14px/19px Roboto;
    letter-spacing: 0px;
    font-weight: 400;
    color: #000000;
    opacity: 1; 
  }
  #device_check {
    border: 1px solid black;
    margin-right: 10px;
    }
    #login_btn {
        color: #FFFFFF;
        font-size: 22px;
        padding: 0;
    }
  
  @media screen and (max-width:767px) {
    .backg-img2{
      width: 70%;
    }
    .loginFormContent1 p {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
    .forgot-backg > div{
      margin-top: 0 !important;
    }
    .form-content  {
        padding: 0 !important;
    }
    .loginFormContent1 {
        padding: 0 !important;
    }
    .backg-img2 {
        height: 100px;
        width: 100px;
    }
    .zlipp-logo {
        height: 100px !important;
        width: 100px !important;
    }
    .loginFormContent1 h5 {
        font-size: 14px;
        padding: 0 !important;
      }
      .loginFormContent1 p {
        text-align: left !important;
        font-size: 14px;
        letter-spacing: 0px;
        font-weight: 10 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
        max-width: 100% !important;
        padding: 0 !important;
      }
      .loginFormContent1 {
        padding: 0;
      }
      .new-passwords {
        margin-top: -230px !important;
      }
      .login-ryt img {
        padding: 0 !important;
      }
      .new-passwords h4{
        font-size: 14px !important;
        padding: 0;
      }
      .new-passwords label{
        font-size: 13px;
        font-weight: bold;
      }
      .usernam-password input {
        padding: 5px !important;
        margin-top: 0 !important;
      }

  }
  @media screen and (max-width:1200px) {
    .loginFormContent1 p {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }
  
   /* spinner code */
   .custom-spinner{
    border-width: 2px;
    border-style: solid;
    border-color: white white white transparent;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    animation: spin 1.5s infinite;
    position: relative;
    margin: 0.01rem auto;
   }
  
   .custom-spinner::before, .custom-spinner::after {
    content: '';
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background: white; 
    position: absolute;
    left: 1.5px;
   }
  
   .custom-spinner::before{
    top: 1.25px
   }
  
   .custom-spinner::after{
    bottom: 1.25px;
   }
   
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }