.member-container {
    .nav-tabs .nav-link {
        font: normal normal normal 16px/21px Roboto;
        font-weight: 500;
        letter-spacing: 0px;
        color: #707070;
        border: none; 
        background-color: transparent;
        padding: 10px 20px;
    }

    .nav-tabs .nav-link.active {
        font: normal normal normal 16px/21px Roboto;
        font-weight: 500;
        letter-spacing: 0px;
        color: #5385EF;
        border-bottom: 3px solid #2580EC;
    }
}