.cards {
    width: 160px;
    height: 107px;
    padding: 0 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background-image: url(./../../Assets/dashboard/imgbin_brand.png);
    background-position: center;
    border-radius: 5px;
    margin: 15px;
}

.collegeCard {
    background-color: #F5EBA3;
}

.cardContain {
    display: flex;
    justify-content: space-between;
    padding-right: 10px;
}

.arrow {
    display: flex;
    justify-content: flex-end;
}

.cardsTitle {
    text-align: center;
    font: normal normal normal 14px/19px Roboto;
    font-weight: 500;
    color: #101A33;
}

.cardCount {
    font: normal normal normal 32px/43px Roboto;
    font-weight: bold;
    letter-spacing: 0px;
}

.collegeCount {
    color: #AC9700;
}

.courseCard {
    background-color: #FAE7F3;
}

.courseCount {
    color: #C3288A;
}

.educatorCard {
    background-color: #EDE6F3;
}

.educatorCount {
    color: #537CBE;
}

.studentCard {
    background-color: #E6FEE4;
}

.studentCount {
    color: #78A574;
}

.videoCard {
    background-color: #F3E6EA;
}

.videoCount {
    color: #CA4B5C;
}

.notesCard {
    background-color: #F0F7DE;
}

.notesCount {
    color: #A9BF6E;
}

.assignmentCard {
    background-color: #E6F3EF;
}

.assignmentCount {
    color: #60C6A7;
}

.assessmentCard {
    background-color: #DFEDF2;
}

.assessmentCount {
    color: #5586D4;
}

.presentationCard {
    background-color: #EDE6F3;
}

.presentationCount {
    color: #8332C9;
}

.onSiteClassCard {
    background-color: #F2F0DF;
}

.onSiteClassCount {
    color: #C7BF37;
}