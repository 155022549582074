.view-student-container {
    background-color: #F7F9FA;
    min-height: calc(100% - 4rem);
    font-family: 'Roboto';

    .backBtn {
        display: flex;
        align-items: center;
        .backText {
            font: normal normal normal 16px/21px Roboto;
            letter-spacing: 0px;
            color: #000000;
            margin-left: 15px;
            cursor: pointer;
        }
    }

    p {
        margin: 0;
        padding: 0;
    }

    .userImg {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .view-student-form-card {
        padding: 1% 1% 1% 1%;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        // border: 1px solid #707070;
        border-radius: 10px;
        opacity: 1;

        .view-student-form-title {
            font: normal normal bold 24px/32px Roboto;
            letter-spacing: 0px;
            color: #000000;
            margin-left: 10px;
        }
    
        .subHeading {
            font: normal normal bold 24px/32px Roboto;
            letter-spacing: 0px;
            color: #2580EC;
            margin-top: 15px;
        }
    
        .input-label {
            font: normal normal normal 16px/21px Roboto;
            letter-spacing: 0px;
            color: #707070;
            margin-bottom: 0.5rem;
            margin-top: 1rem;
        }
        select {
            -webkit-appearance: none; /* remove default arrow in Safari */
            -moz-appearance: none; /* remove default arrow in Firefox */
            appearance: none; /* remove default arrow in other browsers */
            padding: 0 15px;
          }
          .down-arrow{
            position: absolute;
            top: 10px;
            right: 15px;
          }
    
        .custom-input {
            height: 45px;
            background: #F2F7FD 0% 0% no-repeat padding-box;
            border-radius: 5px;
            opacity: 1;
            width: 100%;
            margin-bottom: 0.2rem;
            border: none;
            outline: none;
        }
    
        
       
       .validationError{
                color: red;
        }
        .MuiInputBase-root.MuiInputBase-colorPrimary.css-16c50h-MuiInputBase-root-MuiTablePagination-select,
.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions,
.css-levciy-MuiTablePagination-displayedRows{
    margin: 0;
}
    }
    .update-btn {
        width: 107px;
        height: 40px;
        background: #32496B 0% 0% no-repeat padding-box;
        border-radius: 5px;
        font: normal normal medium 16px/21px Roboto;
        letter-spacing: 0px;
        color: #FFFFFF;
    }
    
}



