.educator-performance-container {
    background-color: #FFFFFF;
    min-height: calc(100% - 4rem);
    font-family: 'Roboto';

    .backBtn {
        display: flex;
        align-items: center;
    }

    .backText {
        font: normal normal medium 16px/21px Roboto;
        letter-spacing: 0px;
        color: #000000;
        margin-left: 10px;
        cursor: pointer;
    }

    .educator-performance-card {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 0.5px solid #707070;
        border-radius: 4px;

        .educator-performance-tracker-title {
            font: normal normal bold 20px/26px Roboto;
            letter-spacing: 0px;
            color: #000000;
        }

        .calendar-icon {
            position: absolute;
            top: 15%;
            right: 5%;
        }

        // input[type="date"]::-webkit-inner-spin-button,
        // input[type="date"]::-webkit-calendar-picker-indicator {
        //     visibility: hidden;
        //     -webkit-appearance: none;
        // }
        // .hide-calendar-icon::-webkit-calendar-picker-indicator {
        //     visibility: none;
        //     opacity: 0;
        //   }
        //   .icon {
        //     background-image: url('./../../../../Assets/commonImg/assign-calendar.svg');
        //     background-repeat: no-repeat;
        //     background-position: center;
        //     width: 20px;
        //     height: 20px;
        //     cursor: pointer;
        //     position: absolute;
        //     right: 0;
        //     top: 15px;
        //   }

        .custom-date-input {
            width: 208px;
            height: 45px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 0.2px solid #C4C4C4;
            border-radius: 5px;
            outline: none;
        }

        .tab-section {
            display: flex;
            width: 100%;
            justify-content: space-between;
            background: #EAF2FC 0% 0% no-repeat padding-box;
            border-radius: 4px;
            font: normal normal normal 16px/21px Segoe UI;
            letter-spacing: 0px;
            color: #707070;
        }

        .table-row {
            background: #EAF2FC 0% 0% no-repeat padding-box;

            .head-title {
                font: normal normal normal 14px/19px Roboto;
                letter-spacing: 0px;
                color: #000000;
                font-weight: 500;
            }
        }

        .table-data {
            cursor: pointer;
            font: normal normal normal 16px/21px Roboto;
            letter-spacing: 0px;
            color: #000000;
            border-bottom: unset;
        }

        .video-search {
            .custom-select-input {
                height: 36px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 310px;
                width: 100%;
                border: none;
                outline: unset;
            }

            .search-btn {
                height: 36px;
                background: #32496B 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                border: 1px solid #FFFFFF;
                border-radius: 310px;
                font: normal normal medium 16px/21px Roboto;
                letter-spacing: 0.51px;
                color: #FFFFFF;
            }
        }
    }
   
}