.view-registered-educator-container {
    padding: 20px;
    position: absolute;
    transition: 0.5s;
    top: 4rem;
    overflow: auto;

    p {
        margin: 0;
        padding: 0;
    }

    .label-input {
        position: relative;
        // width: 100%;
        min-width: 25rem;
        font-family: 'Roboto';
    }

    .search-btn {
        height: 44px;
        box-shadow: 0px 3px 6px #00000029;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        color: #fff;
        display: flex;
        align-items: center;
        padding: 5px 20px;
        margin-left: 5px;
        outline: none;
        font-size: 16px;
        font-weight: 500;
        gap: 10%;
        background: #5284EE 0% 0% no-repeat padding-box;
        border: none;
        border-radius: 22px;
    }

    .search-input {
        padding: 10px 25px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        border-radius: 22px;
        font-size: 16px;
        position: relative;
    }

    .search-btn-close {
        position: absolute;
        top: 10px;
        right: 112px;
        border: 0;
        background: none;
    }

    .view-text {
        font-size: 24px;
        font-weight: 700;
    }

    .main-container {
        // padding: 2% 0;
        // margin: 2% 4% 0 0;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 0.5px solid #70707080;
        border-radius: 5px;
        opacity: 1;
        overflow: auto;
        margin: 5px 10px;

        .filter-container {
            background: #E7EEFE 0% 0% no-repeat padding-box;
            box-shadow: 0px 1px 2px #00000029;
            // border: 0.5px solid #70707080;
            border-radius: 0px 0px 5px 5px;
        }
    }

    .custom-header {
        background-color: #4CAF50;
        /* Green */
        color: white;
    }

    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        .label-input {
            min-width: 15rem;
        }
    }

    .gap-10{
        gap: 10px;
    }
}

.actionBtn {
    background: unset;
    border: none;
}

.activeBtn {
    border: 1px solid #13EC9B;
    color: #13EC9B;
}

.deActiveBtn {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    font: normal normal medium 12px/16px Roboto;
    letter-spacing: 0.65px;
    color: #B1B2BA;

}

.delete-all-btn.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.viewEducatorHeader {
    background: #E7EEFE 0% 0% no-repeat padding-box;
    padding: 15px 10px 10px 10px;

    .viewEducatorDetails {
        font: normal normal bold 20px/26px Roboto;
        letter-spacing: 0px;
        color: #000000;
    }

}

.educatorProfileForm {
    .educatorProfile {
        font: normal normal bold 16px/21px Roboto;
        letter-spacing: 0px;
        color: #2580EC;
    }

    .input-label {
        font: normal normal normal 16px/21px Roboto;
        letter-spacing: 0px;
        color: #707070;
        margin-bottom: 0.5rem;
        margin-top: 1rem;
    }

    .custom-input {
        height: 45px;
        width: 100%;
        margin-bottom: 0.2rem;
        border: none;
        outline: none;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 2px #00000029;
        border-radius: 5px;
    }
}

.approve-btn {
    width: 89px;
    height: 36px;
    background: #5284EE 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border-color: #5284EE;
    font: normal normal normal 16px/21px Roboto;
    font-weight: 500;
    letter-spacing: 0px;
    color: #FFFFFF;
    margin-right: 10px;
}

.decline-btn {
    width: 89px;
    height: 36px;
    background: #FFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 5px;
    font-size: normal normal medium 16px/21px Roboto;
    letter-spacing: 0px;
    color: #5284EE;
    border-color: #5284EE;
}
.pr-0{
 padding-right: 0;
}
.modal-content-wrapper {
    max-height: 90vh; 
    overflow-y: auto;
}
.modal-content-wrapper {
    max-height: 90vh; 
    overflow-y: auto;
}

.modal-content-wrapper::-webkit-scrollbar {
    width: 5px;
}

.modal-content-wrapper::-webkit-scrollbar-thumb {
    border-radius: 5px;
}

.modal-content-wrapper::-webkit-scrollbar-track {
    background: transparent; 
}

.modal-content-wrapper {
    scrollbar-width: thin; 
}
