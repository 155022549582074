.view-student-performance-container {
    background-color: #F7F9FA;
    min-height: calc(100% - 4rem);
    font-family: 'Roboto';

    .backBtn {
        display: flex;
        align-items: center;
    }

    .backText {
        font: normal normal medium 16px/21px Roboto;
        letter-spacing: 0px;
        color: #000000;
        margin-left: 10px;
        cursor: pointer;
    }

    .view-student-performance-card {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 4px;

        .view-student-performance-tracker-title {
            font: normal normal bold 20px/26px Roboto;
            letter-spacing: 0px;
            color: #000000;
        }

        .table-row {
            background: #EAF2FC 0% 0% no-repeat padding-box;
            .head-title {
                font: normal normal normal 14px/19px Roboto;
                letter-spacing: 0px;
                color: #000000;
                font-weight: 500;
            }
        }

        .table-data {
            font: normal normal normal 16px/21px Roboto;
            letter-spacing: 0px;
            color: #000000;
            border-bottom: unset;
        }

        .current-student {
            // height: 68px;
            background: #76A5DE 0% 0% no-repeat padding-box;
            font: normal normal medium 16px/21px Roboto;
            letter-spacing: 0px;
            color: #FFFFFF;
        }

        .cards-slider {
            .cards {
                .cards-content {
                    width: 300px;
                    height: 259px;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    box-shadow: 3px 3px 6px #00000029;
                    border: 0.5px solid #101A33;
                    border-radius: 12px;

                    .card-header {
                        border-bottom: 0.5px solid #101A33;

                        .header-title {
                            font: normal normal bold 22px/29px Roboto;
                            letter-spacing: 0px;
                            color: #000000;
                        }
                    }
                }

            }
        }

    }
}