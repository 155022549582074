.view-digital-book-container {
    background-color: #F7F9FA;
    min-height: calc(100% - 4rem);
    font-family: 'Roboto';

    .backBtn {
        display: flex;
        align-items: center;
    }

    .backText {
        font: normal normal medium 16px/21px Roboto;
        letter-spacing: 0px;
        color: #000000;
        margin-left: 10px;
        cursor: pointer;
    }

    .view-digital-book-card {
        // background: #FFFFFF 0% 0% no-repeat padding-box;
        // border-radius: 4px;

        .view-digital-book-title {
            font: normal normal bold 20px/26px Roboto;
            letter-spacing: 0px;
            color: #000000;
        }

        .table-row {
            background: #76A5DE 0% 0% no-repeat padding-box;
            border: 0.5px solid #707070;
            border-radius: 20px 20px 0px 0px;
            .head-title {
                font: normal normal normal 14px/19px Roboto;
                letter-spacing: 0px;
                color: #000000;
                font-weight: 500;
            }
        }

        .table-data {
            font: normal normal normal 16px/21px Roboto;
            letter-spacing: 0px;
            color: #000000;
            border-bottom: unset;
        }

        .view-digital-book-content {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border: 0.5px solid #70707080;
            border-radius: 20px;
        }
    }
}