@import "~bootstrap/scss/bootstrap";

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.App {
  height: 100%;
}

.custom-btn {
  font-size: 16px;
  font-family: 'Roboto';
  padding: 0.5rem 1rem;
  min-width: 6rem;
}

.btn-primary {
  background: #32496B 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: #fff;
  border: none;
}

.btn-secondary {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 2px solid #32496B;
  border-radius: 5px;
  color: #32496B;
}

.fullWidthOpenSideNav {
  width: 80%;
  margin-left: 19%;
}

.halfWidthOpenSideNav {
  width: 100%;
  margin-left:0px;
}


@media only screen and (min-device-width: 320px) and (max-device-width: 768px) {
  // .halfWidthOpenSideNav {
  //   width: calc(100% - 25%);
  //   margin-left: 25%;
  //   // overflow: auto;
  // }

  // .fullWidthOpenSideNav {
  //   width: 100%;
  //   margin-left: 0;
  //   // overflow: auto;
  // }
  .fullWidthOpenSideNav{
    width: 100%;
    margin-left: 0px;
  }
}

.tableHeaders {
  font: normal normal bold 16px/21px Roboto !important;
  letter-spacing: 0px !important;
  color: #2580EC !important;
}

// .css-16c50h-MuiInputBase-root-MuiTablePagination-select {
//   margin-top: -12px !important;
// }
// .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions {
//   margin-top: -15px !important;
// }
.down-arrow{
  position: absolute !important;
  right: 2px !important;
  top: 5px !important;
  padding: 4px;
  background-color: #fff;
}
.custom-input{
  height: 45px !important;
}
.arrow-icon{
  margin-left: 4px;
}
.cursor-pointer {
  cursor: pointer;
}