.overall-performance-view-container {
    background-color: #FFFFFF;
    min-height: calc(100% - 4rem);
    font-family: 'Roboto';

    .backBtn {
        display: flex;
        align-items: center;
    }

    .backText {
        font: normal normal medium 16px/21px Roboto;
        letter-spacing: 0px;
        color: #000000;
        margin-left: 10px;
        cursor: pointer;
    }

    .overall-performance-view-card {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 0.5px solid #707070;
        border-radius: 4px;

        .overall-performance-view-tracker-title {
            font: normal normal bold 20px/26px Roboto;
            letter-spacing: 0px;
            color: #000000;
        }

        .course-section {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border: 0.5px solid #707070;
            margin: 40px 100px;

            .course-column {

                .custom-course-select-input {
                    height: 64px;
                    max-width: 300px;
                    width: 100%;
                    background: #EAF2FC 0% 0% no-repeat padding-box;
                    border-radius: 5px;
                    border: none;
                    outline: unset;
                    padding: 0 30px;
                }

                .down-arrow {
                    position: absolute;
                    top: 18px;
                    right: 25px;
                }

                .table-row {
                    background: #EAF2FC 0% 0% no-repeat padding-box;
                    font: normal normal normal 14px/19px Roboto;
                    letter-spacing: 0px;
                    color: #000000;
                }
            }
           
        }

        .tab-section {
            background: #EAF2FC 0% 0% no-repeat padding-box;
            border-radius: 4px;
            font: normal normal normal 16px/21px Segoe UI;
            letter-spacing: 0px;
            color: #707070;
        }

        .table-row {
            background: #EAF2FC 0% 0% no-repeat padding-box;
            font: normal normal normal 14px/19px Roboto;
            letter-spacing: 0px;
            color: #000000;
        }

    }
   
}
@media only screen and (max-width: 768px) {
.overall-performance-view-card {
    padding: 10px !important;
    .course-section{
        margin: 10px !important;
        padding: 10px !important;
        .course-column {
            .custom-course-select-input {
                padding: 0 10px !important;
            }
            .down-arrow {
                right: 15px !important;
            }

           
        }
    }
}
    
}