.create-password-container {
    background: #F5F5F5 0% 0% no-repeat padding-box;
    opacity: 1;
    height: 100vh;
    position: relative;

    .blue-curve-img {
        position: absolute;
        top: 65vh;
        left: 0;
    }

    .input-block {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 2px solid #00000070;
        border-radius: 12px;
        height: 50px;
        padding: 20px;
        font-size: 22px;
        // margin-top: 1rem;
        margin-bottom: 1rem;
        width: 100%;
    }

    .create-password-form-container{
        width: 75%;
        text-align: start;
        margin-top: 22%;
        text-align: center;
    }

    .form-check-label {
        color: #808080;
        opacity: 1;
        font-size: 19px;
    }
    .btn-create-password {
        background: transparent linear-gradient(180deg, #08C8F6 0%, #4D5DFB 100%) 0% 0% no-repeat padding-box;
        border-radius: 12px;
        opacity: 1;
        color: white;
        padding: 10px;
        width: 50%;
        font-size: 18px;
        font-weight: bold;
    }

    .welcome-container{
        background-image: url('/Assets/login/back-img.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 100vh;
    }

    .lms-content {
        margin-left: 15%;
        margin-top: 10%;
        text-align: start;

        .welcome-text {
            font-size: 28px;
            font-weight: 600;
            line-height: 37px;
        }

        .welcome-des {
            font-size: 23px;
            font-weight: 600;
            line-height: 27px;
        }

        .mobile-img{
            height: 50vh;
        }
    }

    .show-password{
        position: absolute;
        width: 24px;
        right: 15px;
        top: 53.5%;
    }

    .create-password-circle{
        background-color: #dde3f3;
        border-radius: 50%;
        -o-object-fit: none;
        object-fit: none;
        width: 30%;
    }

    .enterUserNameText {
        text-align: left;
        font-size: 14px;
        line-height: 19px;
        font-family: 'Roboto';
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
    }

    .createText {
        font: normal normal bold 28px/37px Roboto;
        letter-spacing: 0px;
        color: #000352;
    }
    
    .passwordText {
        font: normal normal bold 28px/37px Roboto;
        letter-spacing: 0px;
        color: #4A7BF1;
    }

    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        .create-password-form-container{
            width: 75%;
            text-align: center;
            margin-left: 12%;
            margin-bottom: 10%
        }
    }
}