.changePassword-container {
    .changePasswordHeading {
        text-align: left;
        font: normal normal normal 24px/32px Roboto;
        font-weight: 500;
        letter-spacing: 0px;
        color: #707070;
    }

    .infoHeading {
        text-align: left;
        font: normal normal normal 16px/21px Roboto;
        font-weight: 500;
        letter-spacing: 0px;
        color: #000000;
    }

    .passwordField {
        display: flex;
        justify-content: center;
        align-items: center;

        .custom-input {
            height: 45px;
            background: #FCFCFC 0% 0% no-repeat padding-box;
            border: 1px solid #E7EEFD;
            border-radius: 5px;
            width: 100%;
        }

        .errorMassage {
            font: normal normal 300 12px/16px Roboto;
            letter-spacing: 0px;
            color: #000000;

        }
    }

    .changePassword {
        width: 218px;
        height: 40px;
        background: #5486F0 0% 0% no-repeat padding-box;
        border: 0.5px solid #70707080;
        border-radius: 10px;
        text-align: center;
        font: normal normal medium 16px/21px Roboto;
        letter-spacing: 0px;
        color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
}