.college-list-container {
    background-color: #F7F9FA;
    min-height: calc(100% - 4rem);
    font-family: 'Roboto';

    .backBtn {
        display: flex;
        align-items: center;
    }

    .backText {
        font: normal normal medium 16px/21px Roboto;
        letter-spacing: 0px;
        color: #000000;
        margin-left: 10px;
        cursor: pointer;
        margin-left: 12px;
    }

    .college-list-card {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 20px;

        .college-list-title {
            font: normal normal bold 24px/32px Segoe UI;
            letter-spacing: 0px;
            color: #000000;
        }

        .filter-section {
            column-gap: 30px;
            row-gap: 10px;

            .search-filter {
                .custom-search-input {
                    // max-width: 426px;
                    height: 41px;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border: 1px solid #707070;
                    border-radius: 10px;
                    width: 100%;
                    outline: unset;
                }

                .search-btn {
                    height: 40px;
                    background: #32496B 0% 0% no-repeat padding-box;
                    border: 1px solid #707070;
                    border-radius: 0px 10px 10px 0px;
                    font: normal normal normal 12px/16px Roboto;
                    letter-spacing: 0.24px;
                    color: #FFFFFF;
                }
            }
        }

        .table-row {
            background: #FFFFFF 0% 0% no-repeat padding-box;
border: 1px solid #707070;
border-radius: 20px;
        }

        input[type="checkbox"] {
            display: none;
        }

        .checkmark {
            display: inline-block;
            width: 10px;
            height: 10px;
            border: 1px solid #333;
            border-radius: 50%;
            /* apply border radius */
            vertical-align: middle;
        }

        input[type="checkbox"]:checked+.checkmark {
            background-color: #5f5e5e;
        }
    }
    .cancel-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 122px;
height: 42px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 2px solid #374868;
        border-radius: 8px;
        font: normal normal medium 18px/24px Roboto;
letter-spacing: 0.65px;
color: #32496B;
    }
    .continue-btn{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 113px;
height: 42px;
        background: #32496B 0% 0% no-repeat padding-box;
        border-radius: 8px;
        font: normal normal medium 18px/24px Roboto;
letter-spacing: 0.65px;
color: #FFFFFF;
    }
}