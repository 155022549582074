.grievance-container {
    background-color: #F7F9FA;
    min-height: calc(100% - 4rem);
    font-family: 'Roboto';

    .backBtn {
        display: flex;
        align-items: center;
    }

    .backText {
        font: normal normal medium 16px/21px Roboto;
        letter-spacing: 0px;
        color: #000000;
        margin-left: 10px;
        cursor: pointer;
        margin-left: 12px;
    }

    .grievance-card {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 5px;

        .chat-forum {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 1px solid #707070;
            border-radius: 0px 0px 12px 12px;

            .chat-forum-header {
                background: #EAF2FC 0% 0% no-repeat padding-box;

                .title-of-complaint {
                    font: normal normal normal 20px/26px Roboto;
                    letter-spacing: 0px;
                    color: #000000;
                    font-weight: 500;
                }

                .complaint-date {
                    font: normal normal normal 16px/21px Roboto;
                    letter-spacing: 0px;
                    color: #707070;
                }
            }

            .message-section {
                // height: 390px;
                background-color: #FFFFFF;

                .studentName {
                    font: normal normal medium 14px/19px Roboto;
                    letter-spacing: 0px;
                    color: #1A1A1A;
                }

                .message-time {
                    font: normal normal normal 11px/20px Roboto;
                    letter-spacing: 0px;
                    color: #43425D;
                }

                .add-more {
                    font: normal normal normal 8px/10px Roboto;
                    letter-spacing: 0px;
                    color: #2580EC;
                }

                .message-content {
                    max-width: 283px;
                    width: 100%;
                    // max-height: 92px;
                    background: #EAF2FC 0% 0% no-repeat padding-box;
                    border-radius: 0px 12px 12px 12px;

                    p {
                        font: normal normal normal 11px/20px Roboto;
                        letter-spacing: 0px;
                        color: #000000;
                        margin-bottom: 0px;

                    }
                }
                .reply-message-content{
                    max-width: 283px;
                    width: 100%;
                    background: #EAEAEA 0% 0% no-repeat padding-box;
                    border-radius: 0px 12px 12px 12px;
                    p {
                        font: normal normal normal 11px/20px Roboto;
                        letter-spacing: 0px;
                        color: #000000;
                        margin-bottom: 0px;

                    }
                }

                .fileName {
                    font: normal normal normal 10px/20px Roboto;
                    letter-spacing: 0px;
                    color: #3B86FF;
                }
            }

            .chat-forum-footer {
                background: #DFEBFF 0% 0% no-repeat padding-box;
                box-shadow: 0px -3px 6px #00000029;
                border-radius: 0px 0px 12px 12px;

                .massage-input-field {
                    border: none;
                    background: #DFEBFF 0% 0% no-repeat padding-box;

                }

                .send-message-btn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 30px;
                    height: 30px;
                    background-color: #48BEF2;
                    border-radius: 50%;
                }

                ::placeholder {
                    /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: #3B86FF;
                    opacity: 1;
                    /* Firefox */
                }
            }
        }
    }
}