.universityNameDetails {
    background: #E7EEFE 0% 0% no-repeat padding-box;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;

    .universityName {
        text-align: left;
        font: normal normal bold 32px/43px Roboto;
        letter-spacing: 0px;
        color: #000000;
    }

    .universityCode {
        text-align: left;
        font: normal normal normal 16px/21px Roboto;
        letter-spacing: 0px;
        color: #000000;
    }

    .firstBlockBgImg {
        background-image: url(./../../Assets/dashboard/image1.png);
        background-size: cover;
    }
}

.collegeSection {
    background: #E7EEFE 0% 0% no-repeat padding-box;
    margin-right: 15px;
    width: 100%;
    max-width: 279px;
    height: 89px;
    display: flex;
    justify-content: center;
    // border-radius: 5px;

    .custom-select-input {
        border: none;
        outline: none;
        background-color: #E7EEFE;
        font: normal normal normal 19px/25px Roboto;
        font-weight: 500;
        letter-spacing: 0px;
        color: #000000;
    }

    .no-arrow {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: none;
        background-color: transparent;
        padding: 5px 10px;
        font-size: 16px;
    }

    .no-arrow::ms-expand {
        display: none;
    }

    .no-arrow {
        padding-right: 20px;
        background-position: right center;
        background-repeat: no-repeat;
    }

    .arrow-icon {
        width: 15px;
    }


}

.taskSection {
    background: #E7EEFE 0% 0% no-repeat padding-box;
    width: 100%;
    height: 89px;

    .taskAssigned {
        font: normal normal normal 14px/19px Roboto;
        font-weight: bold;
        letter-spacing: 0px;
        color: #000000;
    }

    .taskAssignedStatus>div {
        font: normal normal 300 12px/16px Roboto;
        letter-spacing: 0px;
        color: #000000;

    }

    .progressStatusSection {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .taskStatusHeading {
        font: normal normal 300 12px/16px Roboto;
        letter-spacing: 0px;
        color: #000000;
    }
}

@media screen and (max-width: 1291px) {
    .firstBlockBgImg>img {
        display: none;
    }
}

.CircularProgressbar-text {
    font-weight: bold;
    font-size: 25px !important;
}