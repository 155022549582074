.header-container {

    p {
        margin: 0;
        padding: 0;
    }

    position: fixed;
    width: 100%;
    z-index: 111;
    margin: 0;
    box-shadow: 0 3px 6px #00000029;
    background-color: #f8f9fa !important;
    min-height: 4rem;
    top: 0;

    .header-row {
        img {
            width: 48px;
            cursor: pointer;
        }
    }

    .logoutIcon {
        background-color: #fff;
        padding: 8px;
        border-radius: 36px;
        box-shadow: 0 3px 6px #00000029;
        margin-bottom: 4px;
    }

    .user-profile-card {
        // min-width: 7.5rem;
        // text-align: left;
        // padding-left: 3%;
        padding: 0 10px;
    }

    .user-name {
        // font-family: 'Roboto';
        // font-size: 26px;
        // font-weight: 700;
        font: normal normal bold 20px/26px Roboto;
    }

    .user-role {
        // font-family: 'Roboto';
        // font-size: 15px;
        // font-weight: 500;
        font: normal normal medium 15px/20px Roboto;
    }
}

.hamburger{
    display: none;
}

// @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
//     .hamburger {
//         display: block;
//         position: absolute;
//         top: 5rem;
//         right: 0;
//         z-index: 10;
//         width: 60px;
//         height: 60px;
//     }
// }

@media screen and (max-width:767px) {
.header-row img{
    width: 40px !important;
}
.logoutIcon {
    padding: 2px !important;
    width: 25px !important;
}
.user-profile-card span,p {
    font-size: 18px !important;
}
.header-container .user-profile-card {
    padding: 0;
}
.all-feature img{
    padding: 0;
    width: 15px;
    height: 15px;
}
.first-column {
    margin-left: -50px;
}
}   