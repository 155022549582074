.calendarAndAnnoucementView {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    // border: 1px solid #707070;
    border-radius: 10px;
    // padding: 20px;
    overflow: hidden;
}

.monthHeading {
    text-align: left;
    font: normal normal bold 14px/19px Roboto;
    letter-spacing: 0px;
    color: #000000;
}

.currentday {
    background-color: #582ACD;
    color: white;
    display: flex;
    justify-content: center;
    width: 41px;
    height: 59px;
    border: 1px solid #707070;
    border-radius: 10px;
    padding: 5px;
    margin-top: 20px;
    margin: 20px 5px 0 5px;
}

.otherday {
    display: flex;
    justify-content: center;
    width: 41px;
    height: 59px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #707070;
    border-radius: 10px;
    padding: 5px;
    margin-top: 20px;
    margin: 20px 5px 0 5px;
}

.heading {
    font: normal normal bold 16px/21px Roboto;
    letter-spacing: 0px;
    color: #000000;
}

.announcementDescription {
    font: normal normal normal 12px/16px Roboto;
    letter-spacing: 0px;
    color: #707070;
}

.announcementheading {
    font: normal normal bold 14px/19px Roboto;
    letter-spacing: 0px;
    color: #000000;
}

.announcementTime {
    font: normal normal normal 14px/19px Roboto;
    letter-spacing: 0px;
    color: #707070;
}

.examDetails {
    font: normal normal normal 16px/21px Roboto;
    letter-spacing: 0px;
    color: #000000;
}

.subDetails {
    font: normal normal normal 10px/13px Roboto;
    letter-spacing: 0px;
    color: #707070;
}

.arrowBtn {
    border: none !important;
    background: unset !important;
}

.skipAll {
    font: normal normal normal 14px/19px Roboto;
    letter-spacing: 0px;
    color: #1D48D8;
}