.go-back {
    display: inline-flex;
    align-items: center;
    padding-left: 1%;
    padding-top: 30px;
  }

  .back-icon {
    width: 15px;
    height: 15px;
    cursor: pointer;
  }

  .back-text {
    margin-left: 2px;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
  }

  @media screen and (max-width:767px){
    .go-back {
        margin-top: 30px !important;
    }   
}