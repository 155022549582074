.view-college-container {
	position: absolute;
	transition: 0.5s;
	top: 4rem;
	overflow: auto;

	p {
		margin: 0;
		padding: 0;
	}

	.label-input {
		position: relative;
		// width: 100%;
		min-width: 25rem;
		font-family: "Roboto";
	}

	.search-btn {
		box-shadow: 0px 3px 6px #00000029;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		color: #fff;
		display: flex;
		align-items: center;
		padding: 5px 20px;
		margin-left: 5px;
		outline: none;
		font-size: 16px;
		font-weight: 500;
		gap: 10%;
		background: #5284ee 0% 0% no-repeat padding-box;
		border: none;
		border-radius: 22px;
	}

	.search-input {
		padding: 10px 25px;
		box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
		border-radius: 22px;
		// border: 1px solid #707070;
		font-size: 16px;
	}

	.view-text {
		font-size: 24px;
		font-weight: 700;
	}

	.main-container {
		// padding: 2% 0;
		// margin: 2% 4% 0 0;
		background: #ffffff 0% 0% no-repeat padding-box;
		border: 0.5px solid #70707080;
		border-radius: 5px;
		opacity: 1;
		overflow: auto;
		margin: 5px 10px;

		.delete-all-btn {
			background: #5284ee 0% 0% no-repeat padding-box;
			border-radius: 5px;
			color: white;
			border: none;
			padding: 10px 20px;
		}
		.filter-container {
			background: #e7eefe 0% 0% no-repeat padding-box;
			box-shadow: 0px 1px 2px #00000029;
			// border: 0.5px solid #70707080;
			border-radius: 0px 0px 5px 5px;
		}
	}

	.custom-header {
		background-color: #4caf50;
		/* Green */
		color: white;
	}

	@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
		.label-input {
			min-width: 15rem;
		}
	}
}

.actionBtn {
	background: unset;
	border: none;
}
.activeBtn {
	border: 1px solid #13ec9b;
	color: #13ec9b;
}
.deActiveBtn {
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	font: normal normal medium 12px/16px Roboto;
	letter-spacing: 0.65px;
	color: #b1b2ba;
}
.delete-all-btn.disabled {
	opacity: 0.5; /* Adjust the opacity for disabled state as needed */
	cursor: not-allowed; /* Optionally change cursor for disabled state */
}
