.lms-content p{
    text-align: center;
    max-width: 70%;
    color: #fff;
}

.lms-content img{  
    margin-left: auto;
    margin-right: auto;
  
}
 
    .forgot-backg-img {
        background: url('../../../Assets/login/login-backg.svg');
        background-size: cover;
        margin: 0 !important;
        background-repeat:no-repeat;
        height: 100vh;
      }

    .lms-content h5{
        font-size: 22px;
        color: #FFFFFF;
    }
      .lms-content p {
        font: 18px Roboto !important;
        color: #FFFFFF;
        text-align: center;
        opacity: 1;
        font-weight: 340;
      }

    .login-form-container p{
        text-align: center !important;
        font-size: 16px !important;
    }

    .login-btn-data input {
        width: 100% !important;
        padding: 10px !important;
    }

     .lms-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
      }
      

      @media screen and (max-width:767px) {
        .forgot-backg-img {
            height: 40vh;
          }
          .login-form-container {
            margin-top: -1px !important;
          }
          
      }