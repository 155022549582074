.college-performance-container {
    background-color: #F7F9FA;
    min-height: calc(100% - 4rem);
    font-family: 'Roboto';

    .college-performance-card {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 4px;

        .performance-tracker-title {
            font: normal normal bold 20px/26px Roboto;
            letter-spacing: 0px;
            color: #000000;
        }

        .custom-select-input {
            height: 45px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 1px solid #707070;
            border-radius: 5px;
            width: 100%;
            // margin-bottom: 1rem;
            margin-bottom: 0.2rem;

            .search-field {
                position: relative;
            }

            .search-btn {
                height: 92%;
                position: absolute;
                right: 0;
                background: #2580EC 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                border: 1px solid #FFFFFF;
                border-radius: 5px;
                padding: 0 17px;
                color: white;
            }
        }

        select {
            -webkit-appearance: none;
            /* remove default arrow in Safari */
            -moz-appearance: none;
            /* remove default arrow in Firefox */
            appearance: none;
            /* remove default arrow in other browsers */
            padding: 0 20px;
        }

        .down-arrow {
            position: absolute;
            top: 10px;
            right: 15px;
        }

        .table-row {
            background: #76A5DE 0% 0% no-repeat padding-box;
            border: 0.5px solid #707070;
            border-radius: 20px 20px 0px 0px;
           
            .head-title {
                font: normal normal normal 14px/19px Roboto;
                letter-spacing: 0px;
                color: #000000;
                font-weight: 500;
            }
        }

        .table-data {
            cursor: pointer;
            font: normal normal normal 16px/21px Roboto;
            letter-spacing: 0px;
            color: #000000;
             border-bottom: unset;
        }

        .chartBox {
            // border: 1px solid;
            border-radius: 20px 20px 0px 0px;
            overflow: scroll;

            .chart-title {
                height: 52px;
                background: #76A5DE 0% 0% no-repeat padding-box;
                // border: 0.5px solid #707070;
                // border-radius: 20px 20px 0px 0px;
                font: normal normal bold 22px/29px Roboto;
                letter-spacing: 0px;
                color: #000000;
                padding: 0 15px;
            }
        }

        .video-content {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border: 0.5px solid #70707080;
            border-radius: 20px;
.select-section{
    .select-search{
        .custom-select-search-input {
            height: 36px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 310px;
            width: 100%;
            border: none;
            outline: unset;
        }
    }
 
}
            .video-search {
                .custom-select-input {
                    height: 36px;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    box-shadow: 0px 3px 6px #00000029;
                    border-radius: 310px;
                    width: 100%;
                    border: none;
                    outline: unset;
                }

                .search-btn {
                    height: 36px;
                    background: #32496B 0% 0% no-repeat padding-box;
                    box-shadow: 0px 3px 6px #00000029;
                    border: 1px solid #FFFFFF;
                    border-radius: 310px;
                    font: normal normal medium 16px/21px Roboto;
                    letter-spacing: 0.51px;
                    color: #FFFFFF;
                }
            }
        }
    }
}

.custom-select {
    position: relative;
    width: 200px;
  }
  
  .custom-select input[type='text'] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .custom-select ul {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    padding: 0;
    margin: 0;
    list-style: none;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .custom-select li {
    padding: 8px;
    cursor: pointer;
  }
  
  .custom-select li:hover {
    background-color: #eee;
  }