.monthlyColumnChart {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 0.1px solid #70707036;
    border-radius: 5px;
}

.title {
    text-align: left;
    font: normal normal bold 20px/26px Roboto;
    letter-spacing: 0px;
    color: #000000;
}

.titleImg {
    background-image: url(../../Assets/dashboard/monthlyProgressIcon.png);
    width: 21px;
    height: 21px;
    background-repeat: no-repeat;
}

.gap10Px {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.classesConducted {
    font: normal normal bold 15px/20px Roboto;
    letter-spacing: 0px;
    color: #000000;
    margin-top: 10px;
}

.monthClasses {
    font: normal normal normal 16px/21px Roboto;
    letter-spacing: 0px;
    color: #000000;
    margin: 20px 43px;
}

.custom-select-input {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 2px #00000029;
    border: 0.20000000298023224px solid #707070;
    border-radius: 5px;
}
.year{
    font: normal normal bold 16px/21px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.36;
}