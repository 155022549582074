.label-input {
    position: relative;
    // width: 100%;
    min-width: 25rem;
    font-family: 'Roboto';
}

.search-btn {
    box-shadow: 0px 3px 6px #00000029;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    color: #fff;
    display: flex;
    align-items: center;
    padding: 5px 20px;
    margin-left: 5px;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    gap: 10%;
    background: #5284EE 0% 0% no-repeat padding-box;
    border: none;
    border-radius: 22px;
}

.search-input {
    padding: 10px 25px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    border-radius: 22px;
    // border: 1px solid #707070;
    font-size: 16px;
}


.search-btn-close{
    position: absolute;
    top: 0.5rem;
    right: 7rem;
}

// .clear-btn {
//     padding: 8px 24px;
//     font-size: 16px;
//     background-color: #2580EC;
//     color: white;
//     border: none;
//     border-radius: 28px;
//     cursor: pointer;
//     outline: none;
//     min-width: 2rem;
//     margin-top: 0.17rem;
// }

// @media screen and (max-width:767px) { 
//     .clear-btn-card{
//         text-align: end;

//         .clear-btn {
//             margin: 1rem 0;
//         }
//     }
// }
