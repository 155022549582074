.complaint-status-container {
    background-color: #F7F9FA;
    min-height: calc(100% - 4rem);
    font-family: 'Roboto';

    .backBtn {
        display: flex;
        align-items: center;
    }

    .backText {
        font: normal normal medium 16px/21px Roboto;
        letter-spacing: 0px;
        color: #000000;
        margin-left: 10px;
        cursor: pointer;
        margin-left: 12px;
    }

    .complaint-status-card {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 5px;

        .status-details {
            border-bottom: 1px solid #70707080;

            .cid-number {
                font: normal normal bold 16px/21px Roboto;
                letter-spacing: 0px;
                color: #32496B;

            }

            .status {
                font: normal normal bold 20px/26px Roboto;
                letter-spacing: 0px;
                color: #32496B;
            }

            .open-button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 157px;
                height: 32px;
                background-color: #FDE0E0;
                font: normal normal normal 24px/32px Roboto;
                letter-spacing: 0px;
                color: #F24949;
            }

            .closed-button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 157px;
                height: 32px;
                background-color: #00AA602B;
                font: normal normal normal 24px/32px Roboto;
                letter-spacing: 0px;
                color: #00AA60;
            }

            .history-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 71px;
                height: 37px;
                background: #2580EC 0% 0% no-repeat padding-box;
                border-radius: 10px;
                font: normal normal medium 14px/19px Roboto;
                letter-spacing: 0px;
                color: #FFFFFF;
                border: none;
            }
        }

        .status-body {
            .heading {
                font: normal normal medium 20px/26px Roboto;
                letter-spacing: 0px;
                color: #32496B;
            }

            .status-description {
                font: normal normal normal 12px/16px Roboto;
                letter-spacing: 0px;
                color: #000000;
            }

            .attached-show-file {
                height: 49px;
                border: 1px solid #70707080;
                border-radius: 5px;
                position: relative;
                .eye-icon{
                    position: absolute;
                    right: 5%;
                }
            }

            .comment-description {
                height: 129px;
                width: 100%;
                box-shadow: 0px 3px 6px #00000029;
                border: 1px solid #70707080;
                border-radius: 5px;
                outline: none;
            }

            .add-more-file {
                font: normal normal normal 14px/19px Roboto;
                letter-spacing: 0px;
                color: #2580EC;
            }

            .send-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 88px;
                height: 37px;
                background: #32496B 0% 0% no-repeat padding-box;
                border-radius: 10px;
                font: normal normal normal 14px/16px SF Pro Text;
                letter-spacing: 0px;
                color: #FFFFFF;
                cursor: pointer;
            }

            .ticket-status {
                height: 334px;
                box-shadow: 0px 3px 6px #00000029;
                border: 1px solid #417EE4;
                border-radius: 5px;

                .ticket-heading {
                    font: normal normal bold 20px/26px Roboto;
                    letter-spacing: 0px;
                    color: #32496B;
                }
                input[type="date"]::-webkit-inner-spin-button,
        input[type="date"]::-webkit-calendar-picker-indicator {
            visibility: hidden;
            -webkit-appearance: none;
        }

                .status-select-input {
                    width: 100%;
                    height: 40px;
                    border: 1px solid #70707080;
                    border-radius: 10px;
                }
            }
        }
    }
}