.uploadedContentHeading {
    font: normal normal bold 20px/26px Roboto;
    letter-spacing: 0px;
    color: #000000;
    margin-top: 15px;
}

.uploadedCard {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    // border: 0.10000000149011612px solid #707070;
    border-radius: 10px;
    padding: 10px;
    margin: 5px 0 30px 0;
}

.assignmentAssessmentText {
    text-align: left;
    font: normal normal normal 21px/28px Roboto;
    font-weight: bold;
    letter-spacing: 0px;
    color: #582ACD;
    display: flex;
    align-items: center;
}