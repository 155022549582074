.bulk-student-promotion-container {

    background-color: #F7F9FA;
    min-height: calc(100% - 4rem);
    font-family: 'Roboto';
    margin-top: 58px;
    padding: 20px;

    .backBtn {
        display: flex;
        align-items: center;
    }

    .backText {
        font: normal normal medium 16px/21px Roboto;
        letter-spacing: 0px;
        color: #000000;
        margin-left: 10px;
        cursor: pointer;
    }

    p {
        margin: 0;
        padding: 0;
    }

    .bulk-student-promotion-card {
        padding: 12px;
        margin-top: 10px;
        margin-left: 20px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 0.5px solid #70707080;
        border-radius: 5px;
        // border: 1px solid #707070;
        border-radius: 10px;
        opacity: 1;

        .bulk-student-promotion-title {
            font: normal normal bold 24px/32px Roboto;
            letter-spacing: 0px;
            color: #000000;
            padding: 0 1% 1% 0%
        }

        .filter-section {
            // background: #EAF2FC 0% 0% no-repeat padding-box;
            // box-shadow: 0px 3px 6px #00000029;
            // border-radius: 5px 5px 0px 0px;
            // padding: 15px 25px;

            .search-field {
                .custom-select-input {
                    height: 45px !important;
                    // background: #FFFFFF 0% 0% no-repeat padding-box;
                    // box-shadow: 0px 3px 6px #00000029;
                    // border-radius: 5px;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    box-shadow: 0px 3px 6px #00000029;
                    border-radius: 310px;
                    width: 100%;
                    margin-bottom: 0.2rem;
                    border: none;
                }

                .search-btn {
                    height: 92%;
                    position: absolute;
                    right: 0;
                    // background: #2580EC 0% 0% no-repeat padding-box;
                    // box-shadow: 0px 3px 6px #00000029;
                    // border: 1px solid #FFFFFF;
                    // border-radius: 5px;
                    background: #5284EE 0% 0% no-repeat padding-box;
                    box-shadow: 0px 3px 6px #00000029;
                    border: 1px solid #FFFFFF;
                    border-radius: 310px;
                    padding: 0 17px;
                    color: white;
                }
            }
        }

        .custom-select-input {
            height: 45px;
            // background: #FFFFFF 0% 0% no-repeat padding-box;
            // border: 0.5px solid #707070;
            // border-radius: 5px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 0.5px solid #7070704D;
            border-radius: 5px;
            width: 100%;
            // margin-bottom: 1rem;
            margin-bottom: 0.2rem;
            outline: none;
        }

        .input-label {
            font: normal normal medium 16px/21px Roboto;
            letter-spacing: 0px;
            color: #707070;
            opacity: 1;
            margin-bottom: 0;
        }

        select {
            -webkit-appearance: none;
            /* remove default arrow in Safari */
            -moz-appearance: none;
            /* remove default arrow in Firefox */
            appearance: none;
            /* remove default arrow in other browsers */
            padding: 0 15px;
        }
        .custom-input {
            height: 45px;
            width: 100%;
            margin-bottom: 0.2rem;
            border: none;
            outline: none;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 1px 2px #00000029;
            border-radius: 5px;
        }
        
        .down-arrow {
            position: absolute;
            top: 10px;
            right: 15px;
        }

        .path {
            color: blue;
        }
    }

    .font-20 {
        font-size: 20px;
    }

    .font-24 {
        font-size: 24px;
    }

}

.preview-submit-btn {
    width: 155px;
    height: 36px;
    background: #5284EE 0% 0% no-repeat padding-box;
    border-radius: 5px;
    border: none;
    text-align: center;
    font: normal normal normal 16px/21px Roboto;
    font-weight: 500;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.reset-btn {
    width: 155px;
    height: 36px;
    background-color: #FFF;
    border: 2px solid #5284EE;
    border-radius: 5px;
    text-align: center;
    font: normal normal normal 16px/21px Roboto;
    font-weight: 500;
    letter-spacing: 0px;
    color: #5284EE;
}

.update-student-course-modal {
    .update-student-course-header {
        .update-student-course-detail {
            font: normal normal medium 20px/26px Roboto;
            letter-spacing: 0px;
            color: #000000;
        }
    }

    .update-student-course-body {
        .header-row {
            height: 20px !important;
        }

        .note {
            font: normal normal normal 14px/19px Roboto;
            letter-spacing: 0px;
            color: #000000;
            margin-bottom: 0;
        }

        .update-btn {
            width: 100px;
            height: 40px;
            background: #5284EE 0% 0% no-repeat padding-box;
            border-radius: 8px;
            font: normal normal medium 15px/20px Roboto;
            letter-spacing: 0.51px;
            color: #FFFFFF;
        }

        .close-btn {
            width: 100px;
            height: 40px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 1px solid #19223A;
            border-radius: 8px;
            font: normal normal medium 15px/20px Roboto;
            letter-spacing: 0.51px;
            color: #323A50;
        }
    }
}

.update-student-promote-modal {
    .update-student-promote-body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .custom-select-course-input {
            height: 45px !important;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 0.5px solid #7070704D;
            border-radius: 5px;
            width: 100%;
            margin-bottom: 0.2rem;
        }

        .note {
            font: normal normal normal 14px/19px Roboto;
            font-weight: 500;
            letter-spacing: 0px;
            color: #000000;
        }

        .continue-btn {
            width: 107px;
            height: 40px;
            background: #5284EE 0% 0% no-repeat padding-box;
            border-radius: 5px;
            text-align: center;
            font: normal normal medium 16px/21px Roboto;
            letter-spacing: 0px;
            color: #FFFFFF;
            border: none;
        }
    }
}

@media screen and (max-width:767px) {
    .bulk-student-promotion-container {
        padding: 0;
        margin-top: 150px;
        margin-bottom: 100px;
    }
}
  .swal2-container.swal2-center.swal2-backdrop-show {
    z-index: 2000 !important; /* Adjust as necessary */
}

