#report-table {
  .main-container {
    padding: 1rem;
    overflow: auto;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 2px #00000029;
    border: 0.5px solid #7070704d;
    border-radius: 5px;
  }
  .report-text{
    margin-top: 9px;
  }
  table,
  .table {
    padding: 0;
    display: table;
    border-spacing: 2px 8px;
    padding: 0;
    position: relative;
  }
  table th {
    color: #5385ef;
  }
  td,
  .td {
    display: table-cell;
    cursor: pointer;

    span {
      padding: 15px;
      height: 50px;
      line-height: 20px;
      display: flex;
    }
  }

  tr,
  .tr {
    display: table-row;
  }

  td:first-child:before,
  .td:first-child:before {
    box-sizing: border-box;
    content: "";
    position: absolute;
    left: 0px;
    right: 12px;
    display: block;
    height: 50px;
    border: 0.5px solid #7070704d;
    border-radius: 4px;
  }

  table {
    border-spacing: 0;
    width: 100%;
  }

  td {
    padding: 5px;
  }

  .tr-group {
    margin-top: -35px;
    position: absolute;
    right: 0;
  }

  .row-numbers-td {
    justify-content: center;
    display: flex;
  }

  .table-hedding {
    text-align: content !important;
  }
  .request-report {
    position: absolute;
    right: 0;
    padding: 40px;
    margin-top: -45px;
    button {
      height: 40px;
      background: var(--unnamed-color-5385ef) 0% 0% no-repeat padding-box;
      background: #5385ef 0% 0% no-repeat padding-box;
      border-radius: 5px;
      opacity: 1;
      border: none;
      color: #fff;
    }
  }
  .report-text {
    font-weight: bold;
  }
  .completed-text {
    color: #33a741;
  }
}

.down-arrow {
  position: absolute;
  top: 10px;
  right: 15px;
}