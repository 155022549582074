.assign-college-container {

    position: absolute;
    transition: 0.5s;
    top: 4rem;
    overflow: auto;

    p {
        margin: 0;
        padding: 0;
    }

    .label-input {
        position: relative;
        // width: 100%;
        min-width: 20rem;
        font-family: 'Roboto';
    }

    .search-btn {
        // background: transparent linear-gradient(180deg, #8061D5 0%, #7C92DB 55%, #816CE5 100%) 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        // border: 1px solid #FFFFFF;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        color: #fff;
        display: flex;
        align-items: center;
        // border-radius: 26px;
        padding: 5px 12px;
        margin-left: 5px;
        outline: none;
        font-size: 12px;
        font-weight: 500;
        gap: 10%;
        background: #32496B 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 0px 10px 10px 0px;
    }

    .search-input {
        padding: 10px 20px;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
        border-radius: 10px;
        border: 1px solid #707070;
        font-size: 12px;
    }

    .assign-text {
        font-size: 24px;
        font-weight: 700;
    }

    .main-container {
        padding: 2% 0;
        margin: 2% 4%;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 20px;
        opacity: 1;
        overflow: auto;
    }

    .table-container {
        font-family: 'Roboto';

        th,
        td {
            text-align: center;
            padding: 1rem;
            border-bottom: 1px solid #C2C2C2;
        }

        th {
            font-size: 16px;
            font-weight: 700;
            color: #707070;
        }

        td:first-child {
            font-size: 16px;
            font-weight: 700;
            max-width: 12rem;
        }

        td {
            font-size: 14px;
            color: #313136;
        }

        th:first-child,
        td:first-child {
            padding-left: 2rem;
        }

        th:last-child,
        th:last-child {
            padding-right: 2rem;
        }
    }

    .custom-btn-card {
        display: flex;
        justify-content: center;
        gap: 10%;
    }


    .round {
        position: relative;
    }

    .round label {
        background-color: #fff;
        border: 1px solid #32496B;
        border-radius: 50%;
        cursor: pointer;
        height: 20px;
        left: 25px;
        position: absolute;
        top: 0;
        width: 20px;
    }

    .round label:after {
        border: 2px solid #fff;
        border-top: none;
        border-right: none;
        content: "";
        height: 5.5px;
        left: 4.5px;
        opacity: 0;
        position: absolute;
        top: 5px;
        transform: rotate(-45deg);
        width: 10px;
    }

    .round input[type="checkbox"] {
        visibility: hidden;
    }

    .round input[type="checkbox"]:checked+label {
        background-color: #32496B;
        border-color: #32496B;
    }

    .round input[type="checkbox"]:checked+label:after {
        opacity: 1;
    }

    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        .custom-btn-card{
            justify-content: flex-start;
            margin: 1% 0;
        }       

        .label-input {
            min-width: 15rem;
        }
    }
}