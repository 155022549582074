.college-admin-container {
    position: absolute;
    background-color: #F7F9FA;
    min-height: calc(100% - 4rem);
    font-family: 'Roboto';
    position: absolute;
    transition: 0.5s;
    top: 4rem;

    p {
        margin: 0;
        padding: 0;
    }

    .college-admin-form-card {
        padding: 1%;
        margin: 0.5% 1.5% 1.5% 1.5%;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 0.5px solid #7070704D;
        border-radius: 10px;
        opacity: 1;
    }

    .college-admin-form-title {
        font-size: 24px;
        font-weight: 700;
        padding: 0 2% 2% 1%
    }

    .input-label {
        color: #707070;
        font-size: 16px;
        margin-bottom: 0.5rem;
    }

    .custom-input {
        height: 45px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 1px 2px #00000029;
        border-radius: 5px;
        width: 100%;
        margin-bottom: 1rem;
        border: none;
    }
    .down-arrow {
        position: absolute;
        top: 10px;
        right: 15px;
    }

    .form-submit-card {
        //padding: 2%;
        margin: 1.5% 2%;
        display: flex;
        gap: 1%;

        .save-btn {
            background-color: #5284EE;
            letter-spacing: 0px;
            color: #FFFFFF;
            font: normal normal normal 16px/21px Roboto;
            font-weight: 500;
            border: 2px solid #5284EE;
            border-radius: 5px;
            padding: 10px 30px;
        }

        .cancel-btn {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 2px solid #5284EE;
            border-radius: 5px;
            color: #5284EE;
            padding: 10px 30px;
        }
    }

    .MuiInputBase-formControl {
        height: 44px;
        background: #F2F7FD 0% 0% no-repeat padding-box;
    }

    .Mui-error .MuiOutlinedInput-notchedOutline {
        border-color: grey !important;
    }

    @media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
        .input-label {
            margin-top: 0.5rem;
        }
    }
    .locality {
        color: #2580EC;
    }
    .mobile-number{
        padding: 12px;
        position: absolute;
        width: 54px;
    }
}

.mandatory-filed{
    color: red;
}
.password-icon{
    position: absolute;
    top: 57%;
    transform: translateY(-50%);
    cursor: pointer;
    right: 22px;
}