.basicColumnChart {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 0.1px solid #70707036;
    border-radius: 5px;
}

.title {
    text-align: left;
    font: normal normal bold 20px/26px Roboto;
    letter-spacing: 0px;
    color: #000000;
}

.titleImg {
    background-image: url(../../../public/assets/tiles/notes-svgrepo-com.svg);
    width: 17px;
    height: 20px;
    background-repeat: no-repeat;
}

.gap10Px {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}
.year{
    font: normal normal bold 16px/21px Roboto;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.36;
}