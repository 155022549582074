.title {
    text-align: left;
    font: normal normal bold 20px/26px Roboto;
    letter-spacing: 0px;
    color: #000000;
}

.titleImg {
    background-image: url(../../../public/assets/tiles/notes-svgrepo-com.svg);
    width: 17px;
    height: 20px;
    background-repeat: no-repeat;
}

.gap10Px {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}