.dashboard-container {
    position: absolute;
    background-color: #F7F9FA;
    min-height: calc(100% - 4rem);
    font-family: 'Roboto';
    transition: 0.5s;
    top: 4rem;
    padding: 1rem;
}

.gap-19-px {
    gap: 19px
}

@media screen and (max-width:767px) {
    .dashboard-container {
        margin-top: 50px;
    }
}