.student-performance-container {
    background-color: #F7F9FA;
    min-height: calc(100% - 4rem);
    font-family: 'Roboto';

    .backBtn {
        display: flex;
        align-items: center;
    }

    .backText {
        font: normal normal medium 16px/21px Roboto;
        letter-spacing: 0px;
        color: #000000;
        margin-left: 10px;
        cursor: pointer;
    }

    .student-performance-card {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 4px;

        .student-performance-tracker-title {
            font: normal normal bold 20px/26px Roboto;
            letter-spacing: 0px;
            color: #000000;
        }

        .custom-date-input {
            width: 208px;
            height: 45px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 0.2px solid #C4C4C4;
            border-radius: 5px;
            outline: none;
        }

        .tab-section {
            display: flex;
            background: #EAF2FC 0% 0% no-repeat padding-box;
            border-radius: 4px;
            font: normal normal normal 16px/21px Segoe UI;
            letter-spacing: 0px;
            color: #707070;
        }

        .table-row {
            background: #EAF2FC 0% 0% no-repeat padding-box;
            .head-title {
                font: normal normal normal 14px/19px Roboto;
                letter-spacing: 0px;
                color: #000000;
                font-weight: 500;
            }
        }

        .table-data {
            cursor: pointer;
            font: normal normal normal 16px/21px Roboto;
            letter-spacing: 0px;
            color: #000000;
            border-bottom: unset;
        }

        .video-search {
            .custom-select-input {
                height: 36px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 310px;
                width: 100%;
                border: none;
                outline: unset;
            }

            .search-btn {
                height: 36px;
                background: #32496B 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                border: 1px solid #FFFFFF;
                border-radius: 310px;
                font: normal normal medium 16px/21px Roboto;
                letter-spacing: 0.51px;
                color: #FFFFFF;
            }
        }

        .cards-slider {
            .cards {
                .cards-content {
                    width: 300px;
                    height: 259px;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    box-shadow: 3px 3px 6px #00000029;
                    border: 0.5px solid #101A33;
                    border-radius: 12px;

                    .card-header {
                        border-bottom: 0.5px solid #101A33;

                        .header-title {
                            font: normal normal bold 22px/29px Roboto;
                            letter-spacing: 0px;
                            color: #000000;
                        }
                    }
                }

            }
        }

    }
}