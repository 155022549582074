.view-student-container {
	background-color: #f7f9fa;
	min-height: calc(100% - 4rem);
	font-family: "Roboto";
	position: absolute;
	padding: 60px 20px 20px 20px;

	.backBtn {
		display: flex;
		align-items: center;
	}

	.backText {
		font: normal normal medium 16px/21px Roboto;
		letter-spacing: 0px;
		color: #000000;
		margin-left: 10px;
		cursor: pointer;
	}

	p {
		margin: 0;
		padding: 0;
	}

	.view-student-form-card {
		background: #ffffff 0% 0% no-repeat padding-box;
		border: 0.5px solid #70707080;
		border-radius: 5px;
		opacity: 1;
		margin: 5px 10px;

		.filter-section {
			margin-right: 40px;
			margin-bottom: 20px;
		}

		.student-list-title {
			font: normal normal bold 24px/32px Roboto;
			letter-spacing: 0px;
			color: #000000;
		}

		.custom-select-input {
			height: 45px;
			background: #ffffff 0% 0% no-repeat padding-box;
			border: 0.5px solid #707070;
			border-radius: 5px;
			width: 100%;
			// margin-bottom: 1rem;
			margin-bottom: 0.2rem;
			outline: none;
		}

		select {
			-webkit-appearance: none;
			/* remove default arrow in Safari */
			-moz-appearance: none;
			/* remove default arrow in Firefox */
			appearance: none;
			/* remove default arrow in other browsers */
			padding: 0 15px;
		}

		.down-arrow {
			position: absolute;
			top: 18px;
			right: 25px;
		}

		.dropdown-button {
			background-color: transparent !important;
			width: 31px;
			height: 31px;
			border: none !important;
			padding-left: 0px !important;
			border-radius: 50% !important;
		}

		.dropdown-toggle::after {
			display: none;
		}

		.status-btn {
			background: #ffffff 0% 0% no-repeat padding-box;
			box-shadow: 0px 3px 6px #00000029;
		}

		.active-btn {
			border: 1px solid #13ec9b;
		}

		.deactive-btn {
			border: 1px solid #ee3c4b;
		}

		.search-field {
			position: relative;

			.custom-search-input {
				width: 400px;
				height: 45px !important;
				background: #ffffff 0% 0% no-repeat padding-box;
				padding: 10px 25px;
				box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
				border-radius: 22px;
				border: none;
			}

			.search-btn {
				height: 100%;
				position: absolute;
				right: 0;
				// top: -2px;
				background: #2580ec 0% 0% no-repeat padding-box;
				box-shadow: 0px 3px 6px #00000029;
				border: 1px solid #ffffff;
				border-radius: 22px;
				padding: 0 10px;
				color: white;
			}
		}

		.search-col {
			padding: 7px !important;

			.search-field {
				.custom-select-input {
					height: 45px !important;
					background: #ffffff 0% 0% no-repeat padding-box;
					box-shadow: 0px 3px 6px #00000029;
					border-radius: 5px;
					width: 100%;
					margin-bottom: 0.2rem;
					border: none;
					// margin-top: -2px;
				}
			}
		}

		@media screen and (max-width: 991px) {
			.search-col {
				padding: 10px !important;
			}
		}

		.delete-all-btn {
			background: #5284ee 0% 0% no-repeat padding-box;
			border-radius: 5px;
			color: white;
			border: none;
			padding: 10px 20px;
		}

		.filterSection {
			background: #e7eefe 0% 0% no-repeat padding-box;
			box-shadow: 0px 1px 2px #00000029;
			// border: 0.5px solid #70707080;
			border-radius: 0px 0px 5px 5px;
		}
	}
	.noDataFoundSection {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;

		.noDataFound {
			text-align: left;
			font: normal normal bold 24px/32px Roboto;
			letter-spacing: 0px;
			color: #000000;
		}
	}
	.studentStatus {
		width: 58px;
		height: 19px;
		background: #7cdf7c40 0% 0% no-repeat padding-box;
		border-radius: 2px;
		font: normal normal normal 10px/13px Roboto;
		letter-spacing: 0px;
		color: #38ba24;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.contact-box {
	.box-content {
		width: 263px;
		height: 231px;
		background: #cbd6f1 0% 0% no-repeat padding-box;
		box-shadow: 3px 3px 6px #00000029;
		border: 1px solid #707070;
		border-radius: 5px;
	}

	.message-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100px;
		height: 40px;
		background: #32496b 0% 0% no-repeat padding-box;
		border: 1px solid #707070;
		border-radius: 8px;
		font: normal normal medium 16px/21px Roboto;
		letter-spacing: 0px;
		color: #ffffff;
	}

	.contact-detail {
		font: normal normal normal 16px/21px Roboto;
		letter-spacing: 0px;
		color: #000000;
	}

	.deactive-btn {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100px;
		height: 40px;
		background: #ffffff 0% 0% no-repeat padding-box;
		border: 1px solid #32496b;
		border-radius: 8px;
		font: normal normal medium 16px/21px Roboto;
		letter-spacing: 0px;
		color: #32496b;
	}

	.delete-text {
		font: normal normal normal 16px/21px Roboto;
		letter-spacing: 0px;
		color: #000000;
	}

	.delete-btn {
		width: 160px;
		height: 40px;
		display: flex;
		justify-content: center;
		align-items: center;
		background: #32496b 0% 0% no-repeat padding-box;
		border: 1px solid #707070;
		border-radius: 8px;
		font: normal normal medium 16px/21px Roboto;
		letter-spacing: 0px;
		color: #ffffff;
	}
}

@media screen and (max-width: 767px) {
	.view-student-container {
		padding: 2rem;
		margin-top: 100px !important;
	}

	.promoteHeader {
		background-color: #e7eefe;
	}
}

.btnCancel {
	width: 132px;
	height: 35px;
	border: 2px solid #5284ee;
	border-radius: 4px;
	font: normal normal medium 16px/21px Roboto;
	letter-spacing: 0px;
	color: #5284ee;
	background-color: #ffffff;
}

.btnPromote {
	width: 132px;
	height: 35px;
	background: #5284ee 0% 0% no-repeat padding-box;
	border-radius: 4px;
	font: normal normal medium 16px/21px Roboto;
	letter-spacing: 0px;
	color: #ffffff;
	border: none;
}

.actionBtn {
	background: unset;
	border: none;
}
.activeBtn {
	border: 1px solid #13ec9b;
	color: #13ec9b;
}
.deActiveBtn {
	background: #ffffff 0% 0% no-repeat padding-box;
	box-shadow: 0px 3px 6px #00000029;
	font: normal normal medium 12px/16px Roboto;
	letter-spacing: 0.65px;
	color: #b1b2ba;
}

.deactivate{
	color: red !important ;
	background: #df7c7c40 0% 0% no-repeat padding-box;
}