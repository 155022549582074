.student-container {
    background-color: #F7F9FA;
    min-height: calc(100% - 4rem);
    font-family: 'Roboto';
    position: absolute;
    top: 4rem;

    p {
        margin: 0;
        padding: 0;
    }

    .student-form-card {
        padding: 1%;
        margin: 0.5% 1.5% 1.5% 1.5%;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 0.5px solid #7070704D;
        border-radius: 10px;
        opacity: 1;

        .student-form-title {
            font: normal normal bold 24px/32px Roboto;
            letter-spacing: 0px;
            color: #000000;
            padding: 0% 0.5%
        }

        .subHeading {
            font: normal normal bold 24px/32px Roboto;
            letter-spacing: 0px;
            color: #2580EC;
            margin: 1% 0;
        }

        .input-label {
            font: normal normal normal 16px/21px Roboto;
            letter-spacing: 0px;
            color: #707070;
            margin-bottom: 0.5rem;
            margin-top: 1rem;
        }

        select {
            -webkit-appearance: none;
            /* remove default arrow in Safari */
            -moz-appearance: none;
            /* remove default arrow in Firefox */
            appearance: none;
            /* remove default arrow in other browsers */
            padding: 0 15px;
        }

        .down-arrow {
            position: absolute;
            top: 10px;
            right: 15px;
        }

        .custom-input {
            height: 45px;
            width: 100%;
            margin-bottom: 0.2rem;
            border: none;
            outline: none;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 1px 2px #00000029;
            border-radius: 5px;
        }

        .validationError {
            color: red;
        }

        .MuiInputBase-root.MuiInputBase-colorPrimary.css-16c50h-MuiInputBase-root-MuiTablePagination-select,
        .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar .MuiTablePagination-actions,
        .css-levciy-MuiTablePagination-displayedRows {
            margin: 0;
        }
    }

    .form-submit-card {
        //padding: 2%;
        margin: 1.5% 2%;
        display: flex;
        gap: 1%;
    .saveBtn {
        width: 107px;
        height: 40px;
        background: #5284EE 0% 0% no-repeat padding-box;
        border-radius: 5px;
        font: normal normal medium 16px/21px Roboto;
        letter-spacing: 0px;
        color: #FFFFFF;
    }

    .resetBtn {
        width: 107px;
        height: 40px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 2px solid #5284EE;
        border-radius: 5px;
        font: normal normal medium 16px/21px Roboto;
        letter-spacing: 0px;
        color: #5284EE;
    }
    }
}



@media screen and (max-width:767px) { 
    .student-container {
        padding: 0 !important;
    }
    .student-form-card {
        margin-top: 100px ;
        max-width: 100%;
    }
    .student-img {
        height: 50px;
        width: 50px;
    }
    .subHeading {
        font-size: 16px !important;
    }
}