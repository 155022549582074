.add-new-event-container {
    background-color: #FFFFFF;
    min-height: calc(100% - 4rem);
    font-family: 'Roboto';

    .backBtn {
        display: flex;
        align-items: center;
    }

    .backText {
        font: normal normal medium 16px/21px Roboto;
        letter-spacing: 0px;
        color: #000000;
        margin-left: 10px;
        cursor: pointer;
        margin-left: 12px;
    }

    .add-new-event-card {
        .new-event-title {
            font: normal normal bold 24px/32px Roboto;
            letter-spacing: 0px;
            color: #000000;
            border-bottom: 1px solid #70707080;
        }

        .new-event-content {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 1px solid #707070;

            .event-label {
                font: normal normal medium 20px/26px Roboto;
                letter-spacing: 0px;
                color: #000000;
                margin-bottom: 1px;
            }

            .custom-input {
                height: 40px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #707070;
                border-radius: 4px;
                width: 100%;
                outline: unset;
            }

            .text-editor-section {
                .text-editor {
                    height: 160px;
                    background-color: #F2F2F2;

                }

                .ql-container.ql-snow {
                    background-color: #F2F2F2;
                }
            }

            .time-date-content {
                .date-title {
                    font: normal normal bold 16px/21px Roboto;
                    letter-spacing: 0px;
                    color: #141414;
                }

                .date-section {
                    background: #F2F2F2 0% 0% no-repeat padding-box;
                    box-shadow: 0px 3px 6px #00000029;
                    border-radius: 8px;

                    .date-label {
                        margin-bottom: 1px;
                    }

                    .select-date-input {
                        height: 40px;
                        background: #FFFFFF 0% 0% no-repeat padding-box;
                        box-shadow: 0px 5px 9px #00000017;
                        border-radius: 4px;
                        width: 100%;
                        border: none;
                        outline: unset;
                    }
                }

                .note {
                    font: normal normal 300 16px/21px Roboto;
                    letter-spacing: 0px;
                    color: #1851DF;
                }
            }

            .send-invite-to {
                font: normal normal medium 20px/26px Roboto;
                letter-spacing: 0px;
                color: #000000;
            }
        }

        .cancel {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 122px;
            height: 42px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border: 2px solid #374868;
            border-radius: 8px;
            font: normal normal medium 18px/24px Roboto;
            letter-spacing: 0.65px;
            color: #32496B;
        }

        .preview {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 113px;
            height: 42px;
            background: #32496B 0% 0% no-repeat padding-box;
            border-radius: 8px;
            font: normal normal medium 18px/24px Roboto;
            letter-spacing: 0.65px;
            color: #FFFFFF;
        }

    }
}

.preview-modal {
    .presentation-content {
        background: #76A5DE 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 12px;
        border: none;

        .paper-presentation {
            font: normal normal normal 20px/26px Roboto;
            letter-spacing: 0px;
            color: #FFFFFF;
        }

        .presentation-details {
            font: normal normal normal 18px/24px Roboto;
            letter-spacing: 0px;
            color: #FFFFFF;
        }

    }

    .presentation-description {
        background: #F5F5F5 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 8px;
    }

    .cancel-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 122px;
        height: 42px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 2px solid #374868;
        border-radius: 8px;
        font: normal normal medium 18px/24px Roboto;
        letter-spacing: 0.65px;
        color: #32496B;
    }

    .create-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 122px;
        height: 42px;
        background: #32496B 0% 0% no-repeat padding-box;
        border-radius: 8px;
        font: normal normal medium 18px/24px Roboto;
        letter-spacing: 0.65px;
        color: #FFFFFF;
    }
}

@media screen and (max-width:582px) {
    .add-new-event-card {
        .new-event-content {
            .text-editor-section {
                .text-editor {
                    margin-bottom: 90px !important;
                }
            }

        }
    }
}