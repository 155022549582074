.task-container {
    background-color: #F7F9FA;
    min-height: calc(100% - 4rem);
    font-family: 'Roboto';

    .task-card {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 4px;

        .input-label {
            font: normal normal normal 16px/21px Roboto;
            letter-spacing: 0px;
            color: #000000;
            font-weight: 500;
        }

        .custom-select-input {
            width: 204px;
            height: 30px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border: 0.5px solid #707070;
            border-radius: 4px;
        }

        .assign-task-title {
            font: normal normal bold 24px/32px Roboto;
            letter-spacing: 0px;
            color: #000000;
        }

        .faculty-detail-title {
            font: normal normal bold 18px/24px Roboto;
            letter-spacing: 0px;
            color: #202D69;
        }

        .table-row {
            background: #EAF2FC 0% 0% no-repeat padding-box;

            .head-title {
                font: normal normal normal 16px/21px Roboto;
                letter-spacing: 0px;
                color: #000000;
                font-weight: 500;
            }
        }

        .table-data {
            font: normal normal normal 16px/21px Roboto;
            letter-spacing: 0px;
            color: #000000;
            border-bottom: unset;
        }

        .view-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 79px;
            height: 30px;
            background: #F2F2F2 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border: 0.5px solid #707070;
            border-radius: 4px;
            font: normal normal normal 15px/20px Roboto;
            letter-spacing: 0px;
            color: #000000;
            cursor: pointer
        }

        .assign-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 87px;
            height: 30px;
            background: #32496B 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 4px;
            font: normal normal normal 15px/20px Roboto;
            letter-spacing: 0px;
            color: #FFFFFF;
            cursor: pointer
        }

        .chart-card-section {
            .card-header {
                border-bottom: 0.5px solid #EFEFEF;

                .task {
                    font: normal normal normal 5px/6px Montserrat;
                    letter-spacing: 0.1px;
                    color: #707070;
                }

                .principal {
                    font: normal normal normal 13px/18px Roboto;
                    letter-spacing: 0px;
                    color: #32496B;
                }

                .custom-select-input {
                    width: 103px;
                    height: 21px;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border: 0.5px solid #707070;
                    border-radius: 4px;
                    font: normal normal normal 12px/16px Roboto !important;
                    letter-spacing: 0px;
                    color: #000000;
                }
            }

        }

        .video-search {
            .custom-search-input {
                height: 36px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 310px;
                width: 100%;
                border: none;
                outline: unset;
            }

            .search-btn {
                height: 36px;
                background: #32496B 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                border: 1px solid #FFFFFF;
                border-radius: 310px;
                font: normal normal medium 16px/21px Roboto;
                letter-spacing: 0.51px;
                color: #FFFFFF;
            }
        }

        .course-title {
            font: normal normal normal 16px/21px Roboto;
            letter-spacing: 0px;
            color: #000000;
            font-weight: 500;
        }

        .course-data {
            font: normal normal normal 16px/21px Roboto;
            letter-spacing: 0px;
            color: #000000;
        }

        .delete-btn {
            width: 90px;
            height: 35px;
            border: 1px solid #EF2525;
            border-radius: 4px;
            font: normal normal medium 16px/21px Roboto;
            letter-spacing: 0px;
            color: #EF2525;
        }
    }

}

.view-modal {
    .assign-subject {
        font: normal normal medium 16px/21px Roboto;
        letter-spacing: 0px;
        color: #000000;
    }

    .modal-body {
        .course-data-content {
            background: #E4E4E4 0% 0% no-repeat padding-box;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 10px;

            .course-title {
                font: normal normal normal 16px/21px Roboto;
                letter-spacing: 0px;
                color: #000000;
                font-weight: 500;
            }

            .course-data-title {
                font: normal normal normal 16px/21px Roboto;
                letter-spacing: 0px;
                color: #000000;
            }

            .delete-btn {
                width: 90px;
                height: 35px;
                border: 1px solid #EF2525;
                border-radius: 4px;
                font: normal normal medium 16px/21px Roboto;
                letter-spacing: 0px;
                color: #EF2525;
            }
            .delete-btn:hover{
                background-color: #EF2525;
                color: #FFFFFF !important;
            }
        }

    }
}

@media screen and (max-width:767px) { 
    .task-container {
        width: 100% !important;
    }

}