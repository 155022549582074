.engaging-student-performance-container {
    background-color: #FFFFFF;
    min-height: calc(100% - 4rem);
    font-family: 'Roboto';

    .backBtn {
        display: flex;
        align-items: center;
    }

    .backText {
        font: normal normal medium 16px/21px Roboto;
        letter-spacing: 0px;
        color: #000000;
        margin-left: 10px;
        cursor: pointer;
    }

    .engaging-student-performance-card {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 4px;

        .engaging-student-performance-tracker-title {
            font: normal normal bold 20px/26px Roboto;
            letter-spacing: 0px;
            color: #000000;
        }

        .tab-section {
            background: #EAF2FC 0% 0% no-repeat padding-box;
            border-radius: 4px;
            font: normal normal normal 16px/21px Segoe UI;
            letter-spacing: 0px;
            color: #707070;
        }

        .table-row {
            background: #EAF2FC 0% 0% no-repeat padding-box;
            font: normal normal normal 14px/19px Roboto;
            letter-spacing: 0px;
            color: #000000;
        }

    }
}