.TableCustomizedBorder {

    box-shadow: 1px 3px 6px #00000029;
    border: 0.5px solid #BDD0FF;
    border-radius: 4px;
    overflow: hidden;

}

.heading {
    font: normal normal normal 16px/21px Roboto;
    letter-spacing: 0px;
    color: #000000;
    margin: 0;
}

.topbox_container {
    
    background-image: url('./../../../public/assets/tiles/backg1.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    
  }
  
  .topbox_container h1 {
    font-size: 1.5em; /* adjust to your desired font size */
    text-align: left;
  }
  
  .topbox_container p {
    font-size: 1em; /* adjust to your desired font size */
    text-align: left;
  }
  