.student-preview-container {
    background-color: #F7F9FA;
    min-height: calc(100% - 4rem);
    font-family: 'Roboto';

    .backBtn {
        display: flex;
        align-items: center;
    }

    .backText {
        font: normal normal medium 16px/21px Roboto;
        letter-spacing: 0px;
        color: #000000;
        margin-left: 10px;
        cursor: pointer;
    }

    p {
        margin: 0;
        padding: 0;
    }

    .student-preview-card {
        padding: 2%;
        margin-top: 1%;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #707070;
        border-radius: 10px;
        opacity: 1;

        .student-preview-card-title {
            font: normal normal bold 24px/32px Roboto;
            letter-spacing: 0px;
            color: #000000;
            padding: 0 1% 1% 0%
        }

        th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-sizeMedium {
            font: normal normal bold 16px/21px Roboto;
            letter-spacing: 0.32px;
            color: #2580EC;
        }

        .path {
            color: #374868;
        }

    }

    .submit-btn {
        width: 95px;
        height: 41px;
        background: #2580EC 0% 0% no-repeat padding-box;
        color: #FFFFFF;
        border: none;
        border-radius: 5px;
    }

    .submit-btn:disabled {
        opacity: 0.5;
    }

    .label-text {
        font: normal normal bold 16px/21px Roboto;
        letter-spacing: 0.32px;
        color: #2580EC;
    }

    .label-value{
        font: normal normal normal 16px/21px Roboto;
    }
}