.basicColumnChart {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 0.1px solid #70707036;
    border-radius: 5px;
}

.title {
    text-align: left;
    font: normal normal bold 20px/26px Roboto;
    letter-spacing: 0px;
    color: #000000;
}

.titleImg {
    background-image: url(../../Assets/dashboard/attendance-overview.png);
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
}

.gap10Px {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.studentEducator {
    width: 98px;
    height: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 2px #00000029;
    border: 0.20000000298023224px solid #707070;
    border-radius: 5px;
    padding: 3px 10px;
    cursor: pointer;
}

.selectField {
    text-align: left;
    font: normal normal normal 12px/16px Roboto;
    letter-spacing: 0px;
    color: #000000;
    // margin-right: 15px;
}

.customSelectInput {
    width: 139px;
    height: 37px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 2px #00000029;
    border: 0.20000000298023224px solid #707070;
    border-radius: 5px;
    text-align: left;
    font: normal normal normal 12px/16px Roboto;
    letter-spacing: 0px;
    color: #000000;
}


.searchField {
    height: 37px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 1px 2px #00000029;
    border: 0.5px solid #707070;
    border-radius: 5px;
    // position: relative;
}

.searchIcon {
    position: absolute;
    right: 10px;
    top: 5px;
}

::placeholder {
    text-align: left;
    font: normal normal normal 16px/21px Roboto;
    letter-spacing: 0px;
    color: #000000;
}

.table {
    margin-top: 20px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
}

.tableHeader {
    background: #E7EEFE 0% 0% no-repeat padding-box;
    // border: 0.5px solid #7070704D;
}
