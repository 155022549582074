.assign-task-container {
    background-color: #F8F9FA;
    min-height: calc(100% - 4rem);
    font-family: 'Roboto';

    .backBtn {
        display: flex;
        align-items: center;
    }

    .backText {
        font: normal normal medium 16px/21px Roboto;
        letter-spacing: 0px;
        color: #000000;
        margin-left: 10px;
        cursor: pointer;
        margin-left: 12px;
    }

    .assign-task-card {
        background: #EAF2FC 0% 0% no-repeat padding-box;

        .faculty-details {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border-radius: 8px;

            .input-label {
                font: normal normal medium 18px/24px Roboto;
                letter-spacing: 0px;
                color: #2B2B2B;
            }

            .custom-select-input {
                max-width: 192px;
                width: 100%;
                height: 46px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #707070;
                border-radius: 4px;
                outline: none;
                padding: 0 10px;
            }

            .selectedCourseField {
                // width: 689px;
                height: 76px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                border: 1px solid #707070;
                border-radius: 8px;
            }

            .clearBtn {
                position: absolute;
                top: 0;
                right: 13px;
                width: 75px;
                height: 76px;
                background: #32496B 0% 0% no-repeat padding-box;
                box-shadow: -3px 0px 6px #00000029;
                border-radius: 0px 8px 8px 0px;
                font: normal normal medium 18px/24px Roboto;
                letter-spacing: 0px;
                color: #FFFFFF;
            }


        }

        .subject-content {
            width: 100%;
            height: 210px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border-radius: 8px;

            .subject-head-section {
                height: 34px;
                background: #B9CDF3 0% 0% no-repeat padding-box;
                border-radius: 8px 8px 0px 0px;

                .subject-text {
                    font: normal normal medium 18px/24px Roboto;
                    letter-spacing: 0px;
                    color: #2B2B2B;
                    opacity: 1;
                    text-decoration-line: underline;
                }

                .subject-search {
                    .custom-search-input {
                        max-width: 260px;
                        width: 100;
                        height: 26px;
                        background: #FFFFFF 0% 0% no-repeat padding-box;
                        border: 0.5px solid #707070;
                        border-radius: 4px;
                        border: none;
                        outline: unset;
                    }

                    .search-btn {
                        // width: 54px;
                        height: 26px;
                        background: #32496B 0% 0% no-repeat padding-box;
                        font: normal normal 300 14px/19px Roboto;
                        letter-spacing: 0px;
                        color: #FFFFFF;
                        text-align: center;
                        border-radius: unset;
                        padding: 3px;
                        border: 1px solid #32496B;
                    }
                }
            }
        }

        .subjected-content {
            width: 269px;
            height: 210px;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border-radius: 8px;

            .subject-head-section {
                height: 34px;
                background: #B9CDF3 0% 0% no-repeat padding-box;
                border-radius: 8px 8px 0px 0px;

                .subject-text {
                    font: normal normal medium 18px/24px Roboto;
                    letter-spacing: 0px;
                    color: #2B2B2B;
                    opacity: 1;
                }

            }

        }

        .date-label {
            font: normal normal normal 16px/21px Roboto;
            letter-spacing: 0px;
            color: #0C254B;
        }

        .date-input-field {
            height: 34px;
            width: 100%;
            background: #FFFFFF 0% 0% no-repeat padding-box;
            border-radius: 4px;
            border: none;
        }
    }

    .preview-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 191px;
        height: 40px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 2px solid #0C254B;
        border-radius: 4px;
        font: normal normal normal 20px/27px Segoe UI;
        letter-spacing: 0px;
        color: #0C254B;
    }

    .faculty-content {
        // height: 210px;
        // background: #FFFFFF 0% 0% no-repeat padding-box;
        // border-radius: 8px;
        // width: 100%;
        .subject-head-section {
            background: #B9CDF3 0% 0% no-repeat padding-box;
            border-radius: 8px 8px 0px 0px;
            font: normal normal normal 18px/24px Roboto;
            letter-spacing: 0px;
            color: #2B2B2B;
            font-weight: 500;

            .subject-text {
                font: normal normal medium 18px/24px Roboto;
                letter-spacing: 0px;
                color: #2B2B2B;
                opacity: 1;
            }

            .subject-search {
                .custom-search-input {
                    max-width: 260px;
                    width: 100%;
                    height: 26px;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    border: 0.5px solid #707070;
                    border-radius: 4px;
                    border: none;
                    outline: unset;
                }

                .search-btn {
                    // width: 54px;
                    height: 26px;
                    background: #32496B 0% 0% no-repeat padding-box;
                    font: normal normal 300 14px/19px Roboto;
                    letter-spacing: 0px;
                    color: #FFFFFF;
                    text-align: center;
                }
            }
        }

        .faculty-sub-title {
            font: normal normal normal 18px/24px Roboto;
            letter-spacing: 0px;
            color: #2B2B2B;
            font-weight: 500;
        }

        .suject-topics {
            font: normal normal normal 16px/21px Roboto;
            letter-spacing: 0px;
            color: #2B2B2B;
        }
    }

    .cancel-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 151px;
        height: 40px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: 2px solid #0C254B;
        border-radius: 4px;
        font: normal normal medium 16px/21px Roboto;
        letter-spacing: 0px;
        color: #32496B;
    }

    .assigntassk-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 151px;
        height: 40px;
        background: #32496B 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border-radius: 4px;
        font: normal normal medium 16px/21px Roboto;
        letter-spacing: 0px;
        color: #FFFFFF;
    }

}