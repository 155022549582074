.subject-list-container {
    position: absolute;
    transition: 0.5s;
    top: 4rem;
    overflow: auto;

    .main-container {
        border: 0.5px solid #70707080;
        border-radius: 5px;
        opacity: 1;
        overflow: auto;
        margin: 5px 10px;

        .listOfSubjects {
            text-align: left;
            font: normal normal bold 24px/32px Roboto;
            letter-spacing: 0px;
            color: #000000;
        }

        .subjectList-table-container {
            font-family: 'Roboto';
            border: none;

            table {
                width: 100%;
            }

            th,
            td {
                text-align: center;
                padding: 1rem;
                border: 1px solid;
                text-align: center;
                font: normal normal normal 16px/21px Roboto;
                letter-spacing: 0px;
                color: #000000;
            }

            p.MuiTablePagination-displayedRows.css-levciy-MuiTablePagination-displayedRows {
                margin-bottom: 0 !important;
                margin-left: -18px;
                margin-right: -18px;
            }
        }
    }
}