.task-progress-view-container {
    background-color: #FFFFFF;
    min-height: calc(100% - 4rem);
    font-family: 'Roboto';

    .backBtn {
        display: flex;
        align-items: center;
    }

    .backText {
        font: normal normal medium 16px/21px Roboto;
        letter-spacing: 0px;
        color: #000000;
        margin-left: 10px;
        cursor: pointer;
    }

    .task-progress-view-card {
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 4px;

        .task-progress-view-tracker-title {
            font: normal normal bold 20px/26px Roboto;
            letter-spacing: 0px;
            color: #000000;
        }

        .course-detail-section {
            .course-detail {
                width: 195px;
                height: 78px;
                background: #EAF2FC 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                border: 0.5px solid #C2CDE0;
                // border: 0.5px solid #417EE4;
                border-radius: 4px;

                // border: none;
                .total-course {
                    font: normal normal bold 24px/32px Roboto;
                    letter-spacing: 0px;
                    color: #101A33;
                    text-shadow: 0px 3px 6px #00000029;
                }

                .course-title {
                    font: normal normal normal 16px/21px Roboto;
                    letter-spacing: 0px;
                    color: #000000;
                    text-shadow: 0px 3px 6px #00000029;
                }
            }
        }

        .active-course-name {
            // height: 68px;
            background: #76A5DE 0% 0% no-repeat padding-box;
            font: normal normal medium 16px/21px Roboto;
            letter-spacing: 0px;
            color: #FFFFFF;
        }

        .table-row {
            height: 41px;
            background: #EAF2FC 0% 0% no-repeat padding-box;

            .head-title {
                font: normal normal normal 14px/19px Roboto;
                letter-spacing: 0px;
                color: #000000;
                font-weight: 500;
            }
        }

        .table-data {
            font: normal normal normal 16px/21px Roboto;
            letter-spacing: 0px;
            color: #000000;
            border-bottom: unset;
        }

    }

}