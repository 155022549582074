.search-field-container {
  display: flex;
  align-items: center;
}

.search-input {
  padding: 0 40px 0 16px;
  height: 45px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 2px #00000029;
  border-radius: 5px;
  width: 100%;
  border: none;
  flex-grow: 1;
  outline: none;
  position: relative;
}
.search-input-covered {
  padding: 0 40px 0 16px;
  height: 45px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 2px #00000029;
  border-radius: 5px;
  width: 100%;
  border: none;
  flex-grow: 1;
  outline: none;
  position: relative;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.clear-button {
  position: absolute;
  right: 95px;
  border: none;
}
.clear-icon{
  border: none;
  height: p;
  padding: 2px;
  background-color: #fff;
  font-size: 26px;
  font-weight: bold;
}
.search-button {
  padding: 8px 16px;
  font-size: 16px;
  background-color: #2580EC;
  color: white;
  border: none;
  cursor: pointer;
  outline: none;
  position: absolute;
  right: 2px;
}
.search-button-covered {
  padding: 8px 16px;
  font-size: 16px;
  background-color: #2580EC;
  color: white;
  border: none;
  border-radius: 28px;
  cursor: pointer;
  outline: none;
  position: absolute;
  right: -11px;
  margin-top: 6px;
}