.solidgaugeWrapper {
    border: 1px solid #8199DB;
    border-radius: 10px;
    background: white;
    padding: 10px;
}

.solidgaugeTitleWrapper {
    font-size: 24px;
    font-family: Roboto;
    letter-spacing: 0px;
    color: #000000;
    display: flex;
    gap: 10px;
    padding: 0px;
    align-items: center;
}